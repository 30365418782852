import axios from 'axios'
import API from '@/js/const/api.js'
import http from '@/js/utils/http'
import { ElMessage } from 'element-plus'
import i18n from '@/plugins/i18n'
import router from '@/router'
import store from '@/store'
import { authErrorMessage } from '@/js/utils/auth'

const state = {
  login: false,
  userInfo: null,
}

const mutations = {
  login(state, payload) {
    state.login = payload
  },
  clearAuthInfo(state) {
    state.login = false
    state.userInfo = null
  },
  setUserInfo(state, payload) {
    state.userInfo = payload
  },
}

const getters = {
  isLogin(state) {
    return !!state.login
  },
  userInfo(state) {
    return state.userInfo
  },
  userId(state) {
    return state.userInfo['b_user_id']
  },
  permissions(state) {
    return state.userInfo ? state.userInfo.permissions : {}
  },
  hasChildUser(state) {
    return state.userInfo ? state.userInfo.hasChildUser : false
  },
}

const actions = {
  /**
   * ログイン
   * @param {Object} context storeのcontext
   * @param {Object} payload リクエストパラメータ
   * @return {Promise} プロミスオブジェクト
   */
  async login({ commit }, payload) {
    await http.post(API.LOGIN, payload)
    commit('login', true)
  },
  /**
   * ログアウト
   * @param {Object} context storeのcontext
   * @return {Promise} プロミスオブジェクト
   */
  async logout({ commit, dispatch, rootGetters }) {
    // Push Subscriptionの削除 (ログイン後に実行すると認証エラーとなる為、事前に削除)
    if (rootGetters['webPush/subscription']) {
      await dispatch('webPush/deleteSubscription', null, { root: true })
    }
    // ログアウト
    try {
      await http.post(API.LOGOUT)
      axios.defaults.headers.common['Authorization'] = ''
      commit('clearAuthInfo')
    } catch (error) {
      ElMessage({
        message: i18n.global.t('auth_js.logout_failed'),
        type: 'error',
      })
    }
  },
  /**
   * ログイン情報を取得
   * @param {Object} context storeのcontext
   * @return {Promise} プロミスオブジェクト
   */
  async userInfo({ commit }) {
    const res = await http.get(API.LOGIN_USER)
    commit('setUserInfo', res.data.data)
  },
  /**
   * 端末認証用APP KEYを作成
   * @return {Promise} プロミスオブジェクト
   */
  async createAppKey() {
    const res = await http.post(API.APP_KEY)
    return res.data.data
  },
  /**
   * 端末認証用APP KEYを削除
   * @return {Promise} プロミスオブジェクト
   */
  async deleteAppKey() {
    await http.delete(API.APP_KEY)
  },
  /**
   * 端末認証用APP KEYを取得
   * @return {Promise} プロミスオブジェクト
   */
  async getAppKey() {
    const res = await http.get(API.APP_KEY)
    return res.data.data
  },
  /**
   * login認証チェック
   * @param {Object} context storeのcontext
   * @param {Object} route
   * @return {Promise} プロミスオブジェクト
   */
  async loginCheck({ commit }, route) {
    const appKey = route.query.appKey ? route.query.appKey : null
    const params = { appKey: appKey }
    const res = await http.post(API.CHECK_LOGIN, params)
    if (res.data.result === 'failed') {
      const message = authErrorMessage(res.data.data.reason)

      ElMessage({
        dangerouslyUseHTMLString: true,
        message: message,
        type: 'error',
        duration: 5000,
      })

      await store.dispatch('auth/logout')
      // ログイン情報をクリア
      commit('clearAuthInfo')

      router.push({ name: 'login', query: { redirect: route.fullPath } })
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
