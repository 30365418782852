import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import VueLazyLoad from 'vue3-lazyload'
import 'element-plus/dist/index.css'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/plugins/i18n'
import '@/plugins/validator'
import '@/plugins/moment'
import VueGtag from 'vue-gtag'
import './registerServiceWorker'
const app = createApp(App)

// 環境毎の設定情報を取得
store.dispatch('app/getSettings').then(() => {
  const settings = store.getters['app/settings']
  const gaTrackingId = settings.gaTrackingId

  if (gaTrackingId) {
    app.use(VueGtag, {
      config: { id: gaTrackingId },
    })
  }

  app.use(i18n)
  app.use(ElementPlus)
  app.use(store)
  app.use(router)
  app.use(VueLazyLoad)
  app.mount('#app')
})
