import i18n from '@/plugins/i18n'
import http from '@/js/utils/http'
import API from '@/js/const/api'

const defaultValue = {
  downCameraInfoDisplayFlag: false,
}

const state = {
  langInfo: 'ja',
  downCameraInfoDisplayFlag: defaultValue.downCameraInfoDisplayFlag,
  settings: null,
}

const mutations = {
  setLang(state, payload) {
    state.langInfo = payload
    i18n.locale = payload
    location.reload()
  },
  setDownCameraInfoDisplayFlag(state, flag) {
    state.downCameraInfoDisplayFlag = flag
  },
  setSettings(state, payload) {
    state.settings = payload
  },
  // 設定を初期値にクリア
  resetSetting(state) {
    state.downCameraInfoDisplayFlag = defaultValue.downCameraInfoDisplayFlag
  },
}

const getters = {
  langInfo(state) {
    return state.langInfo
  },
  downCameraInfoDisplayFlag(state) {
    return state.downCameraInfoDisplayFlag
  },
  settings(state) {
    return state.settings
  },
  environmentMode(state) {
    return state.settings.environmentMode
  },
  isTLT(state) {
    return state.settings && state.settings.environmentMode.startsWith('TLT')
  },
  /**
   * 検証中機能の判定
   * @param {String} feature 機能を表す文字列
   * @return {function(*): boolean} 検証中の機能判定用関数
   */
  hasExperimentalFeature: (state) => (feature) => {
    return !!state.settings.experimentalFeatures.find((d) => d === feature)
  },
}

const actions = {
  /**
   * 環境毎の設定情報を取得
   */
  async getSettings({ commit }, payload) {
    const res = await http.get(API.SETTING_ENV, payload)
    commit('setSettings', res.data.data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
