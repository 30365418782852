import moment from 'moment'
import { zeroPadding } from '@/js/utils/filters'

/**
 * 指定の日付のRFC3339形式の文字列を取得する関数
 * @param date 変換するDateオブジェクト
 * @return 日付の文字列 (分,秒は00)
 */
export function getZeroHourOfDate(date: Date): string {
  // momentがmonthの-1のズレも補完してくれるみたい
  return moment({
    years: date.getFullYear(),
    months: date.getMonth(),
    days: date.getDate(),
    hours: date.getHours(),
  }).format(moment.defaultFormat)
}

/**
 * 文字列からDateオブジェクトを取得する関数
 * @param v 変換元の文字列
 * @return 日付データ
 */
export function getDateObject(v: string): Date {
  return moment(v).toDate()
}

/**
 * 現在時刻の文字列を取得する関数
 * @param format 日付の形式
 * @return 変換後の値
 */
export function getCurrentDate(format = 'YYYY-MM-DD HH:mm:ss'): string {
  const date = new Date()
  return moment(date).format(format)
}

/**
 * 現在時刻からn日前のDateオブジェクを取得する関数
 * @param baseDate 基準となる日付
 * @param backNum 遡る数値
 * @param unit 遡る数値の単位
 * @return Dateオブジェク
 */
export function getBeforeDate(
  baseDate: Date | string,
  backNum: number,
  unit: moment.unitOfTime.DurationConstructor = 'days'
): Date {
  return moment(baseDate).add(-backNum, unit).toDate()
}

/**
 * 現在時刻からn日後のDateオブジェクを取得する関数
 * @param baseDate 基準となる日付
 * @param prevNum 追加数値
 * @param unit 追加数値の単位
 * @return Dateオブジェク
 */
export function getAfterDate(
  baseDate: Date | string,
  prevNum: number,
  unit: moment.unitOfTime.DurationConstructor = 'days'
): Date {
  return moment(baseDate).add(prevNum, unit).toDate()
}

/**
 * ISO形式に変換
 * @param date 日付の文字列もしくは、Dateオブジェクト
 * @param format 日付の形式(指定しないとIEでInvalid Dateになる場合がある)
 * @return 変換後の値
 */
export function getIsoDateStr(date: Date | string, format = 'YYYY-MM-DD HH:mm:ss'): string {
  return moment(date, format).format(moment.defaultFormat)
}

/**
 * 指定のHH:mmの文字列を取得
 * @param date Dateオブジェクト、文字列
 * @param format 日付の形式
 * @return format形式の文字列
 */
export function getTimeStr(date: Date | string, format = 'HH:mm'): string {
  return moment(date).format(format)
}

/**
 * 指定のformatの文字列を取得
 * @param date 変換元の文字列
 * @param format 変換の形式
 * @return formatの文字列
 */
export function getDateStr(date: Date | string, format = 'YYYY/MM/DD'): string {
  return moment(date).format(format)
}

/**
 * 指定のn時間、分、秒間前(後)の日付のDateオブジェクトを取得
 * @param date Dateオブジェクト、文字列
 * @param n 基準日から何日、時間、分、秒前（後）かの指定
 * @param unit 加減する時間の単位(days,hours,minute,seconds)
 * @return Dateオブジェクト
 */
export function getAddTime(
  date: Date | string,
  n: number,
  unit: moment.unitOfTime.DurationConstructor
): Date {
  return moment(date).add(n, unit).toDate()
}

/**
 * 曜日を取得
 * @param date | string 日時
 * @return 曜日の文字列
 */
export function getDayOfWeek(date: Date | string): string {
  // 0~6の数値を取得
  const day = moment(date).day()
  const week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  return week[day]
}

/**
 * 日付データのHH:mmから分数(mm)を算出する(YYYY/MM/DDは利用しない)
 * @param date | string 日時
 * @return 分数(mm)
 */
export function getMinuteFromHHmm(date: Date | string): number {
  return moment(date).hour() * 60 + moment(date).minute()
}

/**
 * 時間選択用のセレクトボックスのoptionを作成
 * @param start 開始時間
 * @param end 終了時間
 * @return セレクトボックス用のoptions
 */
export function getTimeOptions(start: number, end: number): { value: number; label: string }[] {
  const arr = [...Array(end - start).keys()]

  const options: { value: number; label: string }[] = []
  arr.forEach((d) => {
    const h = start + d
    const hh = h === 24 ? '24' : zeroPadding(h % 24, 2)
    options.push({
      value: h * 60,
      label: `${hh}:00`,
    })
  })
  return options
}

/**
 * 年、月、日、時で前の時間かチェック(AはBより前か)
 * @param checkDate チェックする時間(A)
 * @param baseDate 基準の時間(B)
 * @return 以前時間かチェック
 */
export function isBeforeHour(checkDate: Date | string, baseDate: Date | string): boolean {
  return moment(checkDate).isBefore(baseDate, 'hour')
}

/**
 * 同じ時間帯かの判定
 * @param date1 チェックする日時
 * @param date2 チェックする日時
 * @return 同じ時間かチェック
 */
export function isSameTime(
  date1: Date | string,
  date2: Date | string,
  unit: moment.unitOfTime.DurationConstructor
): boolean {
  return moment(date1).isSame(date2, unit)
}

/**
 * 日付の差分取得
 * @param dateFrom 開始
 * @param dateTo 終了
 * @param unit 単位 (days,hours,minute,secondsなど)
 * @param precise 小数まで取得
 * @return 比較した時間の差
 */
export function getDiffTime(
  dateFrom: Date | string,
  dateTo: Date | string,
  unit: moment.unitOfTime.DurationConstructor = 'minutes',
  precise = false
): number {
  const from = moment(dateFrom)
  const to = moment(dateTo)
  return to.diff(from, unit, precise)
}
