import http from '@/js/utils/http'
import html2canvas from 'html2canvas'

const state = {
  videoElm: null,
  resultImageElm: null,
  fisheyeViewerElm: null,
  resultImageSize: null,
}

const mutations = {
  setVideoElm(state, videoElm) {
    state.videoElm = videoElm
  },
  setResultImageElm(state, resultImageElm) {
    state.resultImageElm = resultImageElm
  },
  setFisheyeViewer(state, fisheyeViewerElm) {
    state.fisheyeViewerElm = fisheyeViewerElm
  },
  setResultImageSize(state, resultImageSize) {
    state.resultImageSize = resultImageSize
  },
  resetState(state) {
    state.videoElm = null
    state.resultImageElm = null
    state.fisheyeViewerElm = null
    state.resultImageSize = null
  },
}

const getters = {
  resultImageSize(state) {
    return state.resultImageSize
  },
  fisheyeViewerElm(state) {
    return state.fisheyeViewerElm
  },
}

const actions = {
  /**
   * 過去映像のvideo Elementと軌跡表示のcanvasを1枚のcanvasにして返却(画面上の表示サイズではなく原寸サイズで)
   * 1.過去映像のS3のパスを取得
   * 2.videoのDOMを作成
   * 3.videoの映像のロードが完了したらvideo描画用のcanvasを作成し描画する
   * 4.videoのDOMをappendする
   * 5.映像 + 軌跡のcanvasを作成する
   * @param {String} videoUrl 過去映像取得用のURL
   * @param {Number} currentTime 過去映像の再生位置(秒)
   * @param {Object} cameraDetail カメラの詳細情報
   * @return {HTMLCanvasElement} videoと軌跡表示を結合したcanvas
   */
  async getCaptureCanvas({ state, getters, dispatch }, { videoUrl, currentTime, cameraDetail }) {
    // 過去映像のS3のURLを取得(リダイレクトURLだとCORSの制限で正しく動作しない)
    const res = await http.get(videoUrl, {
      // return_urlを設定するとリダイレクトURLではなく、S3のパスを取得できる
      return_url: 't',
    })

    // videoは直接キャプチャできない為一度canvasに描画する
    let video = document.createElement('video')
    video.crossOrigin = 'anonymous'
    video.style.width = '100%'
    // TODO Chromeで再生位置が0秒の場合に映像が描画されいない。暫定対処として0秒の場合は再生位置を0.1にする
    video.currentTime = currentTime === 0 ? 0.1 : currentTime
    video.src = res.data.url
    // videoのロードが完了後キャプチャを取得
    return new Promise((resolve) => {
      video.addEventListener('canplay', async () => {
        // canvasを生成
        const imgCanvas = document.createElement('canvas')
        // canvasは原寸サイズで作成
        imgCanvas.width = cameraDetail.image_rs_width
        imgCanvas.height = cameraDetail.image_rs_height
        // 画面表示サイズから拡大率を設定
        const context = imgCanvas.getContext('2d')
        context.scale(
          cameraDetail.image_rs_width / getters.resultImageSize.width,
          cameraDetail.image_rs_height / getters.resultImageSize.height
        )
        // styleを設定。幅と高さは原寸サイズを設定(キャプチャ時に画像が劣化しないようにする為)
        imgCanvas.style.width = getters.resultImageSize.width + 'px'
        imgCanvas.style.height = getters.resultImageSize.height + 'px'
        imgCanvas.style.position = 'absolute'
        imgCanvas.style.top = '0'
        imgCanvas.style.left = '0'
        // 画面表示サイズで描画
        context.drawImage(
          video,
          0,
          0,
          getters.resultImageSize.width,
          getters.resultImageSize.height
        )

        // キャプチャする為DOMを追加
        state.resultImageElm.appendChild(imgCanvas)

        const canvas = dispatch('createCaptureCanvas', {
          element: state.resultImageElm,
          cameraDetail: cameraDetail,
        })
        // 不要なDOMを削除。videoもメモリに溜まってしまうので削除
        video = null
        imgCanvas.remove()
        resolve(canvas)
      })
    })
  },
  /**
   * 解析結果(映像 + 軌跡)をcanvas化して返却する(解析結果ダウンロード、360度解析結果表示で利用)
   * @param {HTMLElement} element canvas化する対象のエレメント
   * @param {Object} cameraDetail カメラ詳細情報
   * @return {HTMLCanvasElement} videoと軌跡表示を結合したcanvas
   */
  async createCaptureCanvas({}, { element, cameraDetail }) {
    const scale = cameraDetail.image_rs_width / element.clientWidth

    const canvas = await html2canvas(element, {
      useCORS: true,
      scale: scale,
      // 幅と高さを指定しないと少し歪む
      width: element.clientWidth,
      height: element.clientHeight,
    })

    return canvas
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
