<template>
  <!--ストリーミング映像表示-->
  <video
    v-if="streamingUrl"
    ref="video"
    muted
    playsinline
    @loadeddata="$emit('loadedData', $event)"
    @error="$emit('loadedError')"
  ></video>
  <!--  hls適用のためにloadタイミングが違いますので別のspinnerを追加-->
  <div class="spinner_box" v-else-if="showSpinner">
    <spinner class="spinner" size="60px" />
  </div>
  <!--LIVE表示非サポートカメラ-->
  <div v-else-if="cameraDetail.use_nolive" class="no_data">
    <span>{{ $t('videos.not_support') }}</span>
  </div>
  <!--live映像 データなし-->
  <div v-else-if="useLive" class="no_data_live" :style="noDataLiveStyle">
    <p class="no_data_text1">{{ $t('live.cannot_live') }}</p>
    <p class="no_data_text2">
      {{ $t('live.please_contact') }}
    </p>
  </div>
  <!--過去映像 データなし-->
  <div v-else class="no_data">
    <span>{{ $t('videos.no_data') }}</span>
  </div>
</template>

<script>
import http from '@/js/utils/http'
import API from '@/js/const/api'
import APP from '@/js/const/app'
import Spinner from '@/components/atoms/Spinner'
import { getAddTime, getDateObject, getIsoDateStr } from '@/js/utils/date'
import Hls from 'hls.js'

export default {
  name: 'MultiVideoStream',
  components: {
    Spinner,
  },
  emits: ['loadedData', 'loadedError'],
  props: {
    cameraDetail: {
      type: Object,
    },
    displaySize: {
      type: Object,
    },
    useLive: {
      type: Boolean,
    },
    streamArchiveDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hls: new Hls(),
      streamingUrl: null,
      loading: false,
    }
  },
  async mounted() {
    await this.getStreamUrl()
  },
  beforeUnmount() {
    this.hls.destroy()
  },
  computed: {
    showSpinner() {
      return this.loading || this.streamingUrl === undefined
    },
    noDataLiveStyle() {
      return {
        height: `${this.displaySize.height}px`,
      }
    },
  },
  methods: {
    async getStreamUrl() {
      this.loading = true
      this.streamingUrl = undefined
      let params = {
        mode: 'live',
        expires: APP.STREAMING_EXPIRES_LIVE,
      }
      if (!this.useLive) {
        // TODO UI変更の時(60秒以外の時)にexpiresTimeやend_datetime変更必要
        const streamArchiveDate = getDateObject(getIsoDateStr(this.streamArchiveDate))
        const archiveEndTime = getAddTime(streamArchiveDate, 1, 'minutes')
        params = {
          mode: 'on_demand',
          start_datetime: getIsoDateStr(this.streamArchiveDate),
          end_datetime: getIsoDateStr(archiveEndTime),
          expires: APP.STREAMING_EXPIRES_ARCHIVE,
        }
      }
      const streamingApi = API.STREAMING.replace('{cameraCode}', this.cameraDetail.camera_code)
      try {
        const res = await http.post(streamingApi, params)
        if (res.data.result === 'failed') {
          this.streamingUrl = null
          this.loading = false
          return
        }
        this.streamingUrl = res.data.data.url
        this.$nextTick(() => {
          this.videoPlay()
        })
      } catch (e) {
        // エラーの場合映像の再生の部分に問題があるので初期化する
        this.streamingUrl = null
        this.loading = false
      }
    },
    videoPlay() {
      if (!this.streamingUrl) {
        return
      }
      if (Hls.isSupported()) {
        this.hls.loadSource(this.streamingUrl)
        this.hls.attachMedia(this.$refs.video)
        this.$refs.video.play()
      } else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
        this.$refs.video.src = this.cameraDetail.data_url
      }
      this.loading = false
    },
  },
  watch: {
    streamArchiveDate() {
      if (!this.useLive) {
        this.$nextTick(() => {
          this.getStreamUrl()
        })
      }
    },
    useLive() {
      this.getStreamUrl()
    },
  },
}
</script>

<style scoped lang="scss">
.spinner_box {
  width: 100%;
  .spinner {
    @include x_center;
    @include y_center;
  }
}
.no_data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: $color-dark3;
  background: rgba($color-dark2, 0.1);
}

.no_data_live {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-dark2;
  text-align: center;
  font-weight: bold;
  padding: 0 $size-m;
  flex-direction: column;
  line-height: 1.7;
  width: 100%;
  .no_data_text1 {
    margin-bottom: $size-m;
  }
  .no_data_text2 {
    @include fs($font-size-6);
    font-weight: normal;
    width: 100%;
  }
}
</style>
