<template>
  <div class="currentDate">
    <span class="liveDisplayTime">{{ liveDisplayTime }}</span>
  </div>
</template>

<script>
import { getCurrentDate } from '@/js/utils/date'

const INTERVAL_TIME = 1000

export default {
  name: 'LiveDisplayTime',
  data() {
    return {
      liveDisplayTime: '',
      timeoutID: null,
    }
  },
  created() {
    this.setCurrentTime()
  },
  unmounted() {
    clearTimeout(this.timeoutID)
  },
  methods: {
    /**
     * LIVE映像の表示日時(現在時間を表示)
     */
    setCurrentTime() {
      this.liveDisplayTime = getCurrentDate('YYYY/MM/DD HH:mm:ss')
      this.timeoutID = setTimeout(() => {
        this.setCurrentTime()
      }, INTERVAL_TIME)
    },
  },
}
</script>

<style scoped lang="scss">
.currentDate {
  position: absolute;
  top: $size-m;
  left: $size-m;
  @include fs($font-size-5);
  color: $color-white;
  font-weight: bold;
  text-shadow: 0 0 3px $color-dark1;
  z-index: 1;
}

.liveDisplayTime {
  &::before {
    content: 'LIVE';
    display: inline-block;
    margin-right: $size-s;
    padding: 0 $size-s;
    background: $color-white;
    text-shadow: none;
    color: $color-primary;
  }
}
</style>
