import i18n from '@/plugins/i18n'
import VueI18n from 'vue-i18n'
import { CameraDetail } from '@/@types/typescript-axios/api'
import { getMinuteFromHHmm, getDayOfWeek } from '@/js/utils/date'
import html2canvas from 'html2canvas'
/**
 *  表示するカメラのレートを取得
 * @param cameraDetail カメラの詳細情報
 * @return レート文言
 */
export function getRate(cameraDetail: CameraDetail): VueI18n.TranslateResult {
  if (cameraDetail.use_time_lapse) {
    return i18n.global.t('camera_detail_dialog.rate_fps_interval', [
      convertFpsToIntervalMinutes(cameraDetail.image_fps),
    ])
  } else {
    return getFrameRate(cameraDetail)
  }
}

/**
 * image_fpsを取得時間に変換
 * @param fps カメライメージのfps
 * @return 変換した分
 */
export function convertFpsToIntervalMinutes(fps: number): number {
  let minutes = Math.round(1.0 / fps / 60)
  // DBスキーマの小数桁数の関係で期待の値にならない場合があるので丸める (e.g. 0.0006FPS=約28分間隔だが、30分間隔とする)
  if (minutes > 10) {
    minutes = Math.round(minutes / 10) * 10
  }
  return Math.round(minutes)
}

/**
 * 録画期間内かの判定
 * @param date | string 日時
 * @param cameraDetail カメラ詳細情報
 * @return 範囲内であればtrueを返却
 */
export function checkRecordRange(date: Date | string, cameraDetail: CameraDetail): boolean {
  const minutes = getMinuteFromHHmm(date)
  const day = getDayOfWeek(date)
  let range: { from: number; to: number }

  if (day === 'Sun') {
    range = { from: cameraDetail.timeHoliFrom, to: cameraDetail.timeHoliTo }
  } else if (day === 'Sat') {
    range = { from: cameraDetail.timeSatFrom, to: cameraDetail.timeSatTo }
  } else {
    range = { from: cameraDetail.timeWeekFrom, to: cameraDetail.timeWeekTo }
  }

  if (range.to > 1440) {
    if (minutes < range.from && range.to - 1440 <= minutes) {
      return false
    }
  } else {
    if (minutes < range.from || range.to <= minutes) {
      return false
    }
  }

  return true
}

/**
 *  表示するカメラのフレームレートを取得
 * @param cameraDetail カメラの詳細情報
 * @return fpsレート文言
 */
export function getFrameRate(cameraDetail: CameraDetail): VueI18n.TranslateResult {
  // カメラ本体設定に準拠
  if (cameraDetail.archive_force_raw) {
    return i18n.global.t('camera_detail_dialog.rate_fps_camera')
  }
  // 可変フレームレート
  else if (
    cameraDetail.image_fps_default &&
    cameraDetail.image_fps_default !== cameraDetail.image_fps
  ) {
    return i18n.global.t('camera_detail_dialog.rate_fps_variable', [
      cameraDetail.image_fps_default,
      cameraDetail.image_fps,
    ])
  } else {
    return i18n.global.t('camera_detail_dialog.rate_fps', [cameraDetail.image_fps])
  }
}

/**
 * 映像の画面表示サイズ(幅、高さ)
 * @param cameraDetail カメラの詳細情報
 * @param height プレイヤーの高さ
 * @return 幅と高さ
 */
export function getDisplaySize(
  cameraDetail: CameraDetail,
  height: number
): { width: number; height: number } {
  // 横幅を基準に縦横比を算出
  const wAspect = cameraDetail.image_rs_width / cameraDetail.image_rs_height
  return {
    width: height * wAspect,
    height: height,
  }
}

/**
 * フルスクリーン表示時の映像の画面表示サイズ(幅、高さ)
 * @param cameraDetail カメラの詳細情報
 * @return 幅と高さ
 */
export function getFullscreenDisplaySize(cameraDetail: CameraDetail): {
  width: number
  height: number
} {
  // TODO 開発者ツールを表示していると少しずれる場合がある
  let scale = window.outerHeight / cameraDetail.image_rs_height
  if (window.outerWidth < window.outerHeight) {
    scale = window.outerWidth / cameraDetail.image_rs_width
  }
  return {
    width: cameraDetail.image_rs_width * scale,
    height: cameraDetail.image_rs_height * scale,
  }
}

export async function htmlToImg(element: HTMLElement, cameraDetail: CameraDetail): Promise<string> {
  // TODO personTrackingResultDetail/createCaptureCanvasとほぼ同じ処理だが、domの構成の問題かx軸が少しずれるので別実装にする
  const scrollBar = window.innerWidth - document.body.clientWidth

  const canvas = await html2canvas(element, {
    useCORS: true,
    // 実寸サイズでキャプチャ
    scale: cameraDetail.image_rs_width / element.clientWidth,
    // スクロールすると画像が切れる場合があるので
    scrollX: -scrollBar / 2,
    scrollY: -window.scrollY,
  })

  return canvas.toDataURL('image/jpg')
}
