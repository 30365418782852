<template>
  <div class="no_data">
    <p class="no_data_text1">{{ $t('archive_video.timelapse_fail_text1') }}</p>
    <p class="no_data_text2">
      {{ $t('archive_video.timelapse_fail_text2') }}
    </p>
  </div>
</template>

<script lang="ts" setup></script>

<style scoped lang="scss">
.no_data {
  background: rgba($color-dark2, 0.1);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-dark2;
  text-align: center;
  font-weight: bold;
  padding: 0 $size-m;
  flex-direction: column;
  line-height: 1.7;
  .no_data_text1 {
    margin-bottom: $size-m;
  }
  .no_data_text2 {
    @include fs($font-size-6);
    font-weight: normal;
    width: 100%;
  }
}
</style>
