import { createI18n } from 'vue-i18n'

let lang
const localStorageInfo = JSON.parse(localStorage.getItem('CiaoCameraApp'))
if (localStorageInfo && localStorageInfo.app) {
  lang = localStorageInfo.app.langInfo
}

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = createI18n({
  legacy: false,
  locale: lang || process.env.VUE_APP_I18N_LOCALE || 'ja',
  fallbackLocale: lang || process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ja',
  messages: loadLocaleMessages(),
  globalInjection: true,
  warnHtmlInMessage: 'off',
})

export default i18n
