<template>
  <dialog-base v-model="displayFlag" @approve="search" :okBtnLabel="$t('condition_dialog.okBtn')">
    <template v-slot:title>
      <h1 class="title">{{ $t('condition_dialog.narrow_down') }}</h1>
    </template>
    <template v-slot:body>
      <div>
        <p class="title_dialog">{{ $t('condition_dialog.tag') }}</p>
        <div class="checkbox_wrap">
          <div class="checkbox_content" v-for="tag in tags" :key="tag.tag_code">
            <span class="checkbox_input">
              <simple-checkbox
                v-model="tagConditions"
                :name="tag.tag_code"
                :value="tag.tag_code"
                :label="tag.tag_name"
                :disabled="zeroCamera(tag.cameras)"
                @change="$emit('changeTags', $event)"
              />
            </span>
            <span
              class="checkbox_content_camera_length"
              :class="{ zero_camera: zeroCamera(tag.cameras) }"
              >（{{ tag.cameras.length }}）</span
            >
          </div>
        </div>
        <p class="title_dialog">{{ $t('condition_dialog.function') }}</p>
        <div class="checkbox_wrap">
          <div class="checkbox_content" v-for="f in func" :key="f.value">
            <label class="function_checkbox_label">
              <span class="checkbox_input function_checkbox_wrap">
                <simple-checkbox
                  v-model="funcConditions"
                  :name="f.value"
                  :value="f.value"
                  @change="$emit('changeFunc', $event)"
                  :data-test="`condition-dialog-checkbox-func-${f.value}`"
                />
                <span class="event_icon">
                  <event-icon :event-type="eventIconType(f.value)"></event-icon>
                </span>
                <span>
                  {{ f.name }}
                </span>
              </span>
            </label>
          </div>
        </div>
        <p class="title_dialog">{{ $t('condition_dialog.status') }}</p>
        <div class="checkbox_wrap">
          <div class="checkbox_content" v-for="o in down" :key="o.value">
            <span class="checkbox_input">
              <simple-checkbox
                v-model="downConditions"
                :name="o.value"
                :value="o.value"
                :label="o.name"
                @change="$emit('changeDown', $event)"
                :data-test="`condition-dialog-checkbox-status-${o.value}`"
              />
            </span>
          </div>
        </div>
      </div>
    </template>
  </dialog-base>
</template>

<script>
import { useStore } from 'vuex'
import DialogBase from '@/components/molecules/DialogBase'
import SimpleCheckbox from '@/components/atoms/SimpleCheckbox'
import APP from '@/js/const/app'
import { toCamelCase } from '@/js/utils/util'
import EventIcon from '@/components/atoms/EventIcon'

export default {
  name: 'ConditionDialog',
  components: {
    DialogBase,
    SimpleCheckbox,
    EventIcon,
  },
  props: {
    toggleDisplayState: {
      type: Boolean,
      default: false,
    },
    selectedFunc: {
      type: Array,
      default: () => [],
    },
    selectedTags: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      default: () => [],
    },
    selectedWatchStatus: {
      type: String,
    },
  },
  emits: [
    'changeTags',
    'changeFunc',
    'changeDown',
    'update:toggleDisplayState',
    'update:selectedTags',
    'update:selectedFunc',
    'update:selectedWatchStatus',
    'search',
  ],
  data() {
    const store = useStore()
    return {
      func: APP.FUNC(store.getters['app/settings'].environmentMode),
      down: APP.DOWN,
    }
  },
  computed: {
    displayFlag: {
      get() {
        return this.toggleDisplayState
      },
      set(newVal) {
        if (this.toggleDisplayState !== newVal) {
          this.$emit('update:toggleDisplayState', newVal)
        }
      },
    },
    tagConditions: {
      get() {
        return this.selectedTags
      },
      set(v) {
        this.$emit('update:selectedTags', v)
      },
    },
    funcConditions: {
      get() {
        return this.selectedFunc
      },
      set(v) {
        this.$emit('update:selectedFunc', v)
      },
    },
    downConditions: {
      get() {
        return this.selectedWatchStatus
      },
      set(v) {
        if (v) {
          this.$emit('update:selectedWatchStatus', 'down')
        } else {
          this.$emit('update:selectedWatchStatus', '')
        }
      },
    },
    zeroCamera() {
      return (v) => v === undefined || v.length === 0
    },
  },
  methods: {
    search() {
      this.$emit('search')
      this.displayFlag = false
    },
    eventIconType(value) {
      const event = value || ''
      return toCamelCase(event)
    },
  },
}
</script>

<style scoped lang="scss">
.checkbox_wrap {
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  @include fs($font-size-5);
  @include sm {
    display: block;
  }
}
.checkbox_content {
  flex-basis: 210px;
  display: flex;
}
.checkbox_content:nth-child(2n) {
  margin-left: $size-l;
  @include sm {
    margin-left: 0;
  }
}
.checkbox_content {
  margin-bottom: 30px;
}

.checkbox_input {
  display: inline-block;
  vertical-align: top;
}
.event_icon {
  margin-left: $size-s;
  margin-right: $size-s;
}
.function_checkbox_wrap {
  display: flex;
}
.function_checkbox_label {
  cursor: pointer;
}
.checkbox_content_camera_length {
  @include fs($font-size-4);

  &.zero_camera {
    color: $color-dark4;
  }
}
</style>
