import moment from 'moment'

/**
 * @param v format前の文字列、DateObject
 * @param type フォーマット形式
 * @return formatされた文字列
 */
export function formatDateStr(v: string | Date, type: string): string {
  return moment(v).format(type)
}

/**
 * @param v 秒の数値もしくは文字列
 * @return MM:SSでformatされた文字列
 */
export function formatSecondStr(v: number | string): string {
  const s = Math.floor(Number(v))
  const ss = zeroPadding(s % 60, 2)
  const mm = zeroPadding(Math.floor(s / 60), 2)
  return `${mm}:${ss}`
}

/**
 * @param v 変換元の文字列
 * @param n 文字列の長さ
 * @return formatされた文字列
 */
export function zeroPadding(v: string | number, n: number): string {
  let str = ''
  const zero = [...Array(n).keys()]

  // eslint-disable-next-line
  for (const i of zero) {
    str += '0'
  }

  return (str + String(v)).slice(-n)
}

/**
 * byteをMBに変換する
 * @param v byte
 * @return MB
 */
export function formatByteToMb(v: number): number {
  return Math.ceil(v / 10000) / 100
}

/**
 * 任意の桁で切り捨てする関数
 * @param v 切り捨てする数値
 * @param digit 桁数
 * @return 切り捨てした値
 */
export function decimalFloor(v: number, digit = 1): number {
  const d = Math.pow(10, digit)
  return Math.floor(v * d) / d
}

/**
 * queryが一つの場合 stringなのでarrayに交換する
 * @param query
 * @return formatされたquery
 */
export function formatQueryToArray(query: string | string[]): string[] {
  if (typeof query === 'string') {
    return [query]
  } else {
    return query
  }
}
