import http from '@/js/utils/http.js'
import API from '@/js/const/api'
import APP from '@/js/const/app'
import { formatQueryToArray } from '@/js/utils/filters'

export const CameraListMixin = {
  data() {
    return {
      isLoaded: false,
      // 表示中のページ
      currentPage: 1,
      // 全カメラ件数
      totalCameras: null,
      // カメラの状態
      cameraStatus: {},
      // 1ページに表示するカメラ数
      pageSize: APP.PAGE_LIMIT,
      // カメラデータ
      cameraList: null,
      // 選択したカメラ
      checkedCameras: [],
      // タグ設定用ダイアログ表示用フラグ
      toggleTagSettingDialogFlag: false,
    }
  },
  methods: {
    /**
     * カメラ一覧を取得
     * @param {Object} route urlパラメータのデータ
     */
    getCameras(route) {
      this.isLoaded = false
      // 表示中のページを設定。URLパラメータにページ番号が連携されていない場合は1を設定
      this.currentPage = route ? Number(route.params.page) : this.currentPage

      const extraParams = {
        filter: [],
        tags: [],
        func: [],
      }
      // パラメータがない場合URLのqueryを使う
      const query = route ? route.query : this.$route.query
      if (query.filter || query.tags || query.func || query.watch_status) {
        // queryが一つの場合stringなのでarrayに変換
        extraParams.filter = formatQueryToArray(query.filter) || []
        extraParams.tags = formatQueryToArray(query.tags) || []
        extraParams.func = formatQueryToArray(query.func) || []
        if (query.watch_status) {
          extraParams.watch_status = query.watch_status
        }
      }

      const params = Object.assign(
        {
          page: this.currentPage,
          count: this.pageSize,
        },
        extraParams
      )

      // 処理待ちのリクエストをキャンセルする(連続実行された場合に最後のリクエストのみ実行する)
      http.cancel(`${API.CAMERAS}_get`)

      return http.get(API.CAMERAS, params).then((res) => {
        this.cameraList = res.data.data.cameras
        this.totalCameras = res.data.data.total
        this.cameraStatus = res.data.data.watch
        this.isLoaded = true
      })
    },

    /**
     *  ページの変更
     * @param {Number} page 表示するページ番号
     */
    changePage(page) {
      this.$router.push({
        name: 'list',
        params: { page: page },
        query: this.$route.query,
      })
    },
    /**
     *  カメラ個別のタグ設定用
     * @param {Object} cameraData カメラデータ
     */
    setTag(cameraData) {
      this.checkedCameras = [cameraData]
      this.toggleTagSettingDialogFlag = true
    },
  },
}
