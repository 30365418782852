<template>
  <resize-box @resize="resize">
    <div class="videos" ref="videos" :style="videosStyle">
      <div
        v-for="(camera, index) in cameraListCopy"
        ref="videoWrap"
        :key="index"
        :style="videoWrapStyle(index)"
        class="video_wrap"
      >
        <div v-if="camera.isDummy" class="no_data">
          <span></span>
        </div>
        <!--タイムラプス-->
        <multi-video-timelapse
          v-else-if="camera.use_time_lapse"
          :use-live="useLive"
          :camera-detail="camera"
          :display-size="getDisplaySize(index, camera)"
        />
        <!--ストリーミング-->
        <multi-video-stream
          v-else-if="camera.use_streaming"
          :camera-detail="camera"
          :use-live="useLive"
          :display-size="getDisplaySize(index, camera)"
          :stream-archive-date="streamArchiveDate"
          @loadedData="loadedData"
          @loadedError="loadedError(camera, index)"
        />
        <!--Live-->
        <multi-video-live
          v-else-if="useLive"
          :camera-detail="camera"
          :update-interval="liveUpdateInterval"
          :display-size="getDisplaySize(index, camera)"
        />
        <!--過去映像-->
        <multi-video-archive
          v-else
          :camera-detail="camera"
          @loadedData="loadedData"
          @loadedError="loadedError(camera, index)"
        />
        <transition>
          <span
            v-show="showNav && !camera.isDummy"
            @click="showDetailPage(camera.camera_code)"
            class="video_name link_scale_s"
            ><span>{{ camera.camera_name }}</span
            ><icon size="22" type="keyboard_arrow_right"
          /></span>
        </transition>
      </div>
    </div>
  </resize-box>
</template>

<script>
import APP from '@/js/const/app'
import Icon from '@/components/atoms/Icon'
import ResizeBox from '@/components/atoms/ResizeBox'
import { deepCopy, range } from '@/js/utils/util'
import live from '@/js/utils/live'
import MultiVideoLive from './MultiVideoLive'
import MultiVideoArchive from './MultiVideoArchive'
import MultiVideoTimelapse from './MultiVideoTimelapse'
import MultiVideoStream from './MultiVideoStream'
import { mapGetters } from 'vuex'

// videoのマージン
const videoMargin = 4

export default {
  name: 'videos',
  components: {
    MultiVideoTimelapse,
    MultiVideoArchive,
    MultiVideoLive,
    MultiVideoStream,
    Icon,
    ResizeBox,
  },
  data() {
    return {
      videosWidth: null,
      loadedCount: 0,
      errorCount: 0,
      videoElms: [],
      videosHeight: null,
      cameraListCopy: null,
    }
  },
  props: {
    cameraList: {
      require: true,
      type: Array,
      default: () => [],
    },
    division: {
      require: true,
      type: Number,
    },
    paused: {
      type: Boolean,
      default: true,
    },
    showNav: {
      type: Boolean,
      default: true,
    },
    speed: {
      type: Number,
    },
    displayDate: {
      type: String,
      default: null,
    },
    useLive: {
      type: Boolean,
      default: true,
    },
    archiveDisplayTime: {
      type: Number,
      default: 0,
    },
    streamArchiveDate: {
      type: String,
      default: null,
    },
  },
  emits: ['retryRequestArchive', 'loadeddata'],
  mounted() {
    // カメラデータをコピーする
    this.setCameraCopyData()

    // コンテンツの幅、高さをセット(mounted時はスクロールバーの幅が入ってしまいずれるので$nextTickを利用する)
    this.$nextTick(() => this.setContentSize())
  },
  unmounted() {
    // LIVE映像のリクエスト配列をリセット
    live.resetRequest()
  },
  computed: {
    videoWrapStyle() {
      return (index) => {
        // コンテンツの幅と、分割数によってボックスのサイズを設定する
        const row = APP.MULTI_VIDEO_DIVISION_OPTIONS.find((d) => d.label === this.division).row

        // 横一列の数
        const columns = this.division / row
        // 一列のマージンの合計
        const totalMargin = (columns - 1) * videoMargin
        // videoの右マージン
        const marginRight = index % columns === columns - 1 ? 0 : videoMargin
        // videoの上マージン
        const marginTop = Math.floor(index / columns) === 0 ? 0 : videoMargin

        // 高さ
        const height = Math.floor((this.videosHeight - videoMargin * (row - 1)) / row)
        // 幅
        // TODO - 1 / columnsは幅が収まらず、レイアウトが崩れてしまう場合があるので追加
        const width = (this.videosWidth - totalMargin) / columns - 1 / columns
        return {
          width: `${width}px`,
          height: `${height}px`,
          marginRight: `${marginRight}px`,
          marginTop: `${marginTop}px`,
        }
      }
    },
    videosStyle() {
      return {
        height: `${this.videosHeight}px`,
      }
    },
    liveUpdateInterval() {
      const len = this.cameraListCopy.filter((d) => !d.use_nolive && !d.isDummy).length
      return 1000 / len
    },
    // データがあるカメラデータ数
    hasDataCameraLength() {
      return this.cameraList.filter((d) => d.has_data === true).length
    },
  },
  methods: {
    setCameraCopyData() {
      // cameraListがmountedのタイミングで必ず存在する
      this.cameraListCopy = deepCopy(this.cameraList)

      // カメラのデータ数より分割数が大きい場合はレイアウト崩れ対策でダミーのデータを入れる
      if (this.cameraListCopy.length < this.division) {
        const dummyData = range(this.division - this.cameraListCopy.length).map(() => {
          return { isDummy: true, has_data: false }
        })
        this.cameraListCopy = this.cameraListCopy.concat(dummyData)
      }
    },
    loadedData(e) {
      this.videoElms.push(e.target)
      this.loadedCount++
    },
    loadedError(camera, index) {
      this.loadedCount++
      this.errorCount++
      // 映像がloadできない場合はデータなしとして扱う
      camera.has_data = false
      camera.updateHasDataFlag = true
      this.cameraListCopy[index] = camera

      // エラーの数が有効なカメラ数と同じ場合は過去映像の再読み込みを行う(AWS署名の有効期限切れ対応)
      if (this.errorCount === this.hasDataCameraLength) {
        this.$emit('retryRequestArchive')
      }
    },
    /**
     * 再生速度の変更
     */
    changePlaySpeed() {
      this.videoElms.forEach((d) => {
        d.playbackRate = this.speed
        d.defaultPlaybackRate = this.speed
      })
    },
    /**
     * 詳細ページを表示
     */
    showDetailPage(code) {
      const param = {
        name: 'detail',
        params: { code: code },
      }

      // 過去映像を表示時は表示時刻を連携する
      if (!this.useLive) {
        param.query = { date: this.displayDate }
      }

      // 設定の日付を連携する
      this.$router.push(param)
    },
    /**
     * コンテンツの高さ、幅をセット
     */
    setContentSize() {
      this.videosWidth = this.$refs.videos.clientWidth
      // -24は下部にマージンを空ける為
      this.videosHeight = window.innerHeight - this.$refs.videos.getBoundingClientRect().top - 24
    },
    resize() {
      // リサイズ時にelementの高さと、幅を再設定
      this.setContentSize()
    },
    resetArchives() {
      this.videoElms = []
      this.loadedCount = 0
      this.errorCount = 0
      // has_dataプロパティを書き換えているので、オリジナルのデータ初期化し直す
      this.setCameraCopyData()
    },
    /**
     * 映像の表示サイズ
     * @param {Number} index 映像の表示順
     * @param {Object} cameraDetail 映像の詳細情報
     * @return {Object} 映像の表示する幅、高さ
     */
    getDisplaySize(index, cameraDetail) {
      const width = parseInt(this.videoWrapStyle(index).width)
      const height = parseInt(this.videoWrapStyle(index).height)
      // 横幅を基準に縦横比を算出
      const wAspect = cameraDetail.image_rs_width / cameraDetail.image_rs_height

      // 横幅が指定のボックスサイズより小さい場合は高さを100%で表示する
      if (height * wAspect < width) {
        return {
          width: height * wAspect,
          height: height,
        }
      } else {
        // 横幅が指定のボックスサイズより大きい場合は幅を100%で表示する
        // 高さを基準に縦横比を算出
        const hAspect = cameraDetail.image_rs_height / cameraDetail.image_rs_width
        return {
          width: width,
          height: width * hAspect,
        }
      }
    },
  },
  watch: {
    cameraList() {
      // 過去映像のデータが更新されたタイミングで初期化
      this.resetArchives()
    },
    useLive(v) {
      // ライブ映像に切り替えたタイミングで、過去映像の表示設定を初期化
      if (v) {
        this.resetArchives()
      }
    },
    loadedCount(v) {
      // videoのloadedの完了をカウントして全てのvideoがロード完了したのを判定する
      if (v === this.hasDataCameraLength && this.videoElms.length > 0) {
        // 再生速度をセット
        this.changePlaySpeed()
        // 全てのロード（エラー）が完了したら、再生基準にするエレメントを連携する
        this.$emit('loadeddata', {
          videoElm: this.videoElms[this.videoElms.length - 1],
          // 1つでもロードに成功しているかのフラグ
          hasLoadedVideo: this.errorCount !== this.hasDataCameraLength,
        })
      }
    },
    paused(v) {
      // アクティブなカメラを全て再生、停止する
      this.videoElms.forEach((d) => {
        if (v) {
          d.pause()
        } else {
          d.play()
        }
      })
    },
    speed() {
      this.changePlaySpeed()
    },
    archiveDisplayTime(v) {
      // seekBarが操作された場合に映像の表示時間を変更
      this.videoElms.forEach((video) => {
        video.currentTime = v
      })
    },
  },
}
</script>

<style scoped lang="scss">
.videos {
  display: flex;
  flex-wrap: wrap;
}

.video_wrap {
  position: relative;
  line-height: 1;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-align: center;

  video {
    margin: 0 auto;
    height: 100%;
    width: 100%;
    background: rgba($color-dark2, 0.1);
  }

  .video_name {
    @include fs($font-size-6);
    position: absolute;
    top: $size-s;
    right: $size-s;
    background: rgba($color-white, 0.5);
    padding: $size-xs 0 $size-xs $size-s;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.no_data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: $color-dark3;
  background: rgba($color-dark2, 0.1);
}

@include fade_animation($duration-fast);
</style>
