/* eslint-disable no-console */

import { register } from 'register-service-worker'
import store from '@/store/index'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      // console.log(
      //   'App is being served from cache by a service worker.\n' +
      //     'For more details, visit https://goo.gl/AFskqB'
      // )
    },
    async registered(registration) {
      let subscription = null
      // ブラウザがwebプッシュをサポートしている場合のみPushSubscriptionを取得
      if (store.getters['webPush/isWebPushSupported']) {
        // ログイン後に利用する為storeに登録
        subscription = await registration.pushManager.getSubscription()
      }
      // 購読が許可されていない場合はnullが設定される
      store.commit('webPush/setSubscription', subscription)
      // 画面表示以外の任意のタイミングで購読の許可を行う為storeに保存しておく
      store.commit('webPush/setRegistration', registration)
      // 購読状態をstoreにセット(値をリアクティブに利用したい為、storeで管理する)
      store.commit('webPush/setNotificationPermission')
    },
    cached() {
      // console.log('Content has been cached for offline use.')
    },
    updatefound() {
      // console.log('New content is downloading.')
    },
    updated() {
      // console.log('New content is available; please refresh.')
    },
    offline() {
      // console.log('No internet connection found. App is running in offline mode.')
    },
    error() {
      // console.error('Error during service worker registration:', error)
    },
  })
}
