<template>
  <section>
    <camera-list-header
      v-model:checkedAll="checkedAll"
      :displayCheckBox="displayCheckBox"
      :displayTagSettingBtn="displayTagSettingBtn"
      :selectedTabName="selectedTabName"
      :isLoaded="isLoaded"
      @toggleTagBtn="toggleTagBtn"
      @toggleTagSettingDialog="toggleTagSettingDialog"
      @selectTab="selectTab"
      @selectCamera="selectCamera"
    />
    <div class="cameras_sub_head">
      <display-range
        v-if="selectedTabName !== 'multi'"
        :total="totalCameras"
        :currentPage="currentPage"
        :isLoaded="isLoaded"
      />
      <div class="search_conditions">
        <search-conditions
          :routeQuery="routeQuery"
          @unsetQueryParams="unsetQueryParams"
          @updateDownQuery="updateDownQuery"
        />
      </div>
    </div>
    <watch-status-notice
      v-if="downCameraNotice"
      :camera-status="cameraStatus"
      @updateDownQuery="updateDownQuery"
    >
    </watch-status-notice>
    <!-- カメラ一覧 -->
    <div class="camera_list">
      <content-box :isLoaded="isLoaded">
        <camera-list
          v-if="selectedTabName !== 'multi'"
          listType="cameras"
          :displayType="selectedTabName"
          :cameraList="cameraList"
          :displayCheckBox="displayCheckBox"
          :useAllCheck="checkedAll"
          :totalCameras="totalCameras"
          :pageSize="pageSize"
          :currentPage="currentPage"
          :noSelectCamera="noSelectCamera"
          :checkedCameras="checkedCameras"
          :downCameraCondition="downCameraCondition"
          @cameraChecked="cameraChecked"
          @setTag="setTag"
          @openMap="openMap"
          @changePage="changePage"
          @clearFilter="clearFilter"
        />
        <!-- マルチ映像 -->
        <multi-videos
          v-if="selectedTabName === 'multi'"
          :division="division"
          @changeDivision="changeDivision"
          @clearFilter="clearFilter"
        ></multi-videos>
      </content-box>
    </div>
    <!-- タグ設定用のダイアログ-->
    <tag-dialog
      v-model:toggleState="toggleTagSettingDialogFlag"
      :checkedCameras="checkedCameras"
      :displayCheckBox="displayCheckBox"
      @updateCameraList="updateCameras"
    />
  </section>
</template>

<script>
/**
 * 共通処理をmixinに定義する
 */
import { CameraListMixin } from '@/js/mixin/CameraList.js'
import TagDialog from '@/components/organisms/TagDialog'
import CameraList from '@/components/organisms/CameraList'
import ContentBox from '@/components/molecules/ContentBox'
import SearchConditions from './components/SearchConditions'
import DisplayRange from './components/DisplayRange'
import CameraListHeader from './components/CameraListHeader'
import MultiVideos from './components/MultiVideos'
import { mapGetters } from 'vuex'
import WatchStatusNotice from './components/WatchStatusNotice'
import { formatQueryToArray } from '@/js/utils/filters'
import { deepCopy } from '@/js/utils/util'

const cameraSearchConditions = ['watch_status', 'filter', 'tags', 'func']
const stringQuery = ['watch_status', 'view', 'division']

export default {
  name: 'Cameras',
  mixins: [CameraListMixin],
  components: {
    MultiVideos,
    CameraList,
    TagDialog,
    SearchConditions,
    DisplayRange,
    CameraListHeader,
    ContentBox,
    WatchStatusNotice,
  },
  data() {
    return {
      // タグ設定モード切り替え用フラグ
      isDisplayTagSetting: false,
      // 選択したカメラ
      checkedCameras: [],
      // 全件チェック用
      checkedAll: false,
      noSelectCamera: false,
      // 障害カメラ絞り込む状態
      downCameraCondition: false,
    }
  },
  created() {
    this.getCameras(this.$route)
  },
  /**
   * コンポーネントは再利用されて、ルートだけ変わる時に呼ばれる(主にページングが実行された場合)
   */
  beforeRouteUpdate(to, from, next) {
    const changeState = cameraSearchConditions.some(
      (key) => JSON.stringify(to.query[key]) !== JSON.stringify(from.query[key])
    )
    // カメラ検索に必要な情報(障害、タグ、キーワード、機能,ページ)が変わった場合だけ実行する
    // 関係ない情報が変わった場合は実行しない為
    if (changeState || (to.params && to.params.page !== from.params.page)) {
      // カメラ一覧を取得
      this.getCameras(to)
    }

    next()
  },
  computed: {
    ...mapGetters({
      // 障害中カメラ情報の表示切り替え用
      downCameraInfoDisplayFlag: 'app/downCameraInfoDisplayFlag',
      tags: 'tag/tags',
    }),
    selectedTabName() {
      return this.$route.query.view || 'list'
    },
    // マルチ映像の分割数
    division() {
      // 指定がない場合は分割数は4
      return Number(this.$route.query.division) || 4
    },
    /**
     * タグ設定ボタンの表示切り替え
     */
    displayTagSettingBtn() {
      return this.checkedCameras.length > 0
    },
    /**
     * カメラ選択用にチェックボックの表示切り替え
     */
    displayCheckBox() {
      return this.isDisplayTagSetting && this.selectedTabName === 'list'
    },
    /**
     * 故障中のカメラの有無フラグ
     */
    downCameraNotice() {
      return this.cameraStatus.down > 0 && this.downCameraInfoDisplayFlag
    },
    //  URLから現在のqueryを呼ぶ
    routeQuery() {
      const query = {
        filter: formatQueryToArray(this.$route.query.filter) || [],
        tags: formatQueryToArray(this.$route.query.tags) || [],
        func: formatQueryToArray(this.$route.query.func) || [],
      }
      // viewがある場合URLに維持する
      stringQuery.forEach((key) => {
        if (this.$route.query[key]) {
          query[key] = this.$route.query[key]
        }
      })

      return query
    },
  },
  methods: {
    /**
     *  検索フィルタをクリア
     */
    clearFilter() {
      this.totalCameras = null
      const query = {}
      this.routerPush(query)
    },
    /**
     *  タグの選択状態の変更
     */
    toggleTagBtn() {
      this.isDisplayTagSetting = !this.isDisplayTagSetting
    },
    /**
     *  タグ設定のダイアログの表示切り替え
     */
    toggleTagSettingDialog() {
      this.toggleTagSettingDialogFlag = !this.toggleTagSettingDialogFlag
    },
    /**
     *  タグを設定する対象カメラを選択
     * @param {Array} cameras タグを設定するカメラ
     */
    cameraChecked(cameras) {
      this.checkedCameras = cameras
    },
    /**
     *  タグを設定設定する対象カメラの選択をクリア
     */
    resetCheckedCameras() {
      this.checkedCameras = []
      this.checkedAll = false
    },
    /**
     *  タグ設定が行われた後にカメラリストと、選択中のカメラの更新
     */
    updateCameras() {
      // タグコードを配列に入れる
      const storeTagCodes = this.tags.map((tag) => tag.tag_code)

      if (this.routeQuery.tags.some((tag) => !storeTagCodes.includes(tag))) {
        // 削除タグがある場合
        const query = deepCopy(this.routeQuery)
        query.tags = query.tags.filter((tag) => storeTagCodes.includes(tag))
        this.routerPush(query)
      } else {
        this.getCameras(this.$route)
      }
      // カメラの選択をクリア
      this.resetCheckedCameras()
    },
    /**
     *  タブの選択
     * @param {String} view 選択したタブのview
     */
    selectTab(view) {
      // URLパラメーターに適用する為
      const query = Object.assign({}, this.$route.query, { view })
      this.routerPush(query, this.$route.params.page)
    },
    /**
     * カメラ選択状態変更やviewをmapに変更
     */
    openMap() {
      this.noSelectCamera = false
      this.selectTab('map')
    },
    /**
     * カメラ選択状態変更
     */
    selectCamera() {
      this.noSelectCamera = true
    },
    /**
     * マルチカメラ分割状態変更
     */
    changeDivision(v) {
      const query = Object.assign({}, this.$route.query, { division: v })
      this.routerPush(query, this.$route.params.page)
    },
    /**
     * 障害カメラ状態の更新
     * @param {String} status 更新する値('down',')
     */
    updateDownQuery(status) {
      const query = deepCopy(this.routeQuery)
      query.watch_status = status
      if (status === 'down') {
        this.downCameraCondition = true
      } else {
        this.downCameraCondition = false
        delete query.watch_status
      }
      this.routerPush(query)
    },
    /**
     * 検索フィルタの更新
     * @param {String} type 削除する対象「キーワード、タグ、機能」のいずれか
     * @param {String} value 削除する値(キーワード、タグ)
     */
    unsetQueryParams(type, value) {
      //  直接 query = this.$route.queryを使うとrouter.pushができない
      const query = deepCopy(this.routeQuery)

      if (type === 'keyword') {
        query.filter = query.filter.filter((d) => d !== value)
      } else if (type === 'tag') {
        query.tags = query.tags.filter((d) => d !== value)
      } else if (type === 'func') {
        query.func = query.func.filter((d) => d !== value)
      }
      this.routerPush(query)
    },
    /**
     * routerの更新
     * @param {Object} query 更新するquery
     * @param {Number} page 削除する値(キーワード、タグ)
     */
    routerPush(query, page = 1) {
      this.$router
        .push({
          name: 'list',
          params: { page: page },
          query: query,
        })
        .catch(() => {})
    },
  },
  watch: {
    checkedAll(v) {
      this.checkedCameras = v === true ? this.cameraList : []
    },
    cameraList() {
      // カメラにタグ設定中の時は維持するために
      if (!this.toggleTagSettingDialogFlag) {
        this.resetCheckedCameras()
      }
    },
    selectedTabName() {
      this.resetCheckedCameras()
    },
  },
}
</script>

<style scoped lang="scss">
.camera_list {
  margin: 0 $size-xl $size-xl $size-xl;
  @include sm {
    margin: $size-m;
  }
}

.cameras_sub_head {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: $size-l $size-xl $size-l;
  @include sm {
    margin: $size-l $size-m $size-m;
  }
}

.search_conditions {
  margin-left: $size-m;
}
</style>
