<template>
  <section
    v-if="!isZeroItem"
    class="multi_videos"
    ref="multiVideos"
    :class="{ full_screen: fullScreenFlag }"
    data-test="multi-videos"
  >
    <white-Box :padding="0">
      <div class="player" @mousemove="showController" @mouseleave="hideController">
        <transition>
          <multi-videos-header
            v-model:startDate="startDate"
            :currentPage="currentPage"
            :isLoaded="isArchiveLoaded"
            :totalCameras="totalCameras"
            :division="division"
            :fullScreen="fullScreenFlag"
            @changeDisplayDate="changeDisplayDate"
            @pageChange="pageChange"
          ></multi-videos-header>
        </transition>
        <div class="player_wrap">
          <content-box :isLoaded="isArchiveLoaded">
            <videos
              :camera-list="cameras"
              :division="division"
              :paused="isPaused"
              :show-nav="cameraInfo.showDate"
              :speed="speed"
              :display-date="displayDate"
              :use-live="useLive && permissions.live"
              :total-cameras="totalCameras"
              :archive-display-time="seekBarValue"
              :stream-archive-date="streamArchiveDate"
              @retryRequestArchive="retryRequestArchive"
              @loadeddata="loadedArchiveData"
            ></videos>
          </content-box>
          <transition v-if="cameraList">
            <div @mousemove="showKeepController" @mouseleave="doNotKeepController">
              <player-controller
                v-show="isShowController"
                v-model="archiveDisplayTime"
                :is-paused="isPaused"
                :duration="duration"
                :use-capture="false"
                v-model:use-live="useLive"
                :use-full-screen="true"
                v-model:full-screen-flag="fullScreenFlag"
                :can-live="permissions.live"
                :is-multi-video="true"
                :speed="speed"
                :useSpeedNav="!useLive"
                :isArchiveLoaded="isArchiveLoaded"
                :division="division"
                @toggleNav="toggleNav"
                @togglePlayPause="togglePlayPause"
                @change="currentTimeChange"
                @fullScreen="toggleFullScreen"
                @showLastArchive="showLastArchive"
                @changeSpeed="changePlaySpeed"
                @showController="showController"
                @changeDivision="changeDivision"
              />
            </div>
          </transition>
          <!-- 再生中の日時 -->
          <transition>
            <div v-if="cameraInfo.showDate">
              <live-display-time v-if="useLive && permissions.live"></live-display-time>
              <archive-display-time
                v-else
                :start-time="archiveStartTime"
                :is-paused="isPaused"
                :current-time="archiveDisplayTime"
              ></archive-display-time>
            </div>
          </transition>
        </div>
      </div>
    </white-Box>
  </section>
  <!-- ゼロ表示 -->
  <zero-item v-else @clear="$emit('clearFilter')" />
</template>

<script>
import { CameraListMixin } from '@/js/mixin/CameraList.js'
import ContentBox from '@/components/molecules/ContentBox'
import WhiteBox from '@/components/atoms/WhiteBox'
import MultiVideosHeader from './components/MultiVideosHeader'
import Videos from './components/Videos'
import PlayerController from '@/components/organisms/PlayerController'
import http from '@/js/utils/http.js'
import API from '@/js/const/api'
import ZeroItem from '../ZeroItem'
import { fullScreen, exitFullScreen, documentGetFullscreenElement } from '@/js/utils/util'
import { formatDateStr, formatSecondStr } from '@/js/utils/filters'
import { getIsoDateStr, getTimeStr, getAddTime, getDateStr, getDateObject } from '@/js/utils/date'
import LiveDisplayTime from '@/components/organisms/LiveDisplayTime'
import ArchiveDisplayTime from '@/components/organisms/ArchiveDisplayTime'
import { mapGetters } from 'vuex'

// マルチ映像はFPSがカメラによってことなる場合があるので固定値にする
const archiveIntervalTime = 300
// 自動再生の最大再生回数（60分見たら停止する)
const autoPlayMaxCount = 60
// 無操作でコントローラを非表示にかかる時間
const deactivateTime = 3000
// 過去映像再生の再実行の最大リトライ回数
const maxArchiveRetryPlayCount = 5
// 過去映像再生の再実行のインターバル
const archivePlayRetryIntervalSec = 5

export default {
  name: 'MultiVideos',
  mixins: [CameraListMixin],
  components: {
    ArchiveDisplayTime,
    LiveDisplayTime,
    PlayerController,
    ContentBox,
    WhiteBox,
    MultiVideosHeader,
    Videos,
    ZeroItem,
  },
  data() {
    return {
      // 過去映像データ
      archiveData: null,
      // 過去映像の読み込み完了のフラグ
      isArchiveLoaded: false,
      // コントローラーの表示フラグ
      isShowController: false,
      // ツールチップの表示フラグ
      isShowToolTip: false,
      // 過去映像の再生時間
      archiveDisplayTime: 0,
      // 映像の停止フラグ
      isPaused: true,
      // 再生スピード
      speed: 1,
      // 表示する過去映像の日付
      startDate: null,
      // カメラデータ
      displayCameraData: [],
      // 表示する映像
      cameras: null,
      // 再生の基準になるvideo element
      videoElm: null,
      // 映像の長さ
      duration: 60,
      // 画角変更用のコントローラーの表示フラグ(falseで固定)
      displayToolTipController: false,
      // 自動再生用のカウント 60回(60分)で停止する
      autoPlayCount: 0,
      // ライブカメラの利用
      useLive: null,
      // フルスクリーン表示
      fullScreenFlag: false,
      //  無操作をわかるためのtimeout
      mouseActionTimeout: null,
      // シークバーを動かした際に過去映像の再生時間を変更するための値
      seekBarValue: 0,
      // 過去映像の再取得用フラグ
      retriedRequestArchive: false,
      // 再生のリトライ用のタイマー
      archivePlayRetryTimeoutId: null,
      // 過去映像の再読み込みのリトライ回数
      archiveRetryCount: 0,
      // 過去映像の再読み込み中フラグ
      isArchivePlayRetrying: false,
      // controller固定フラグ
      keepControllerFlag: false,
      // ストリーミング用date
      streamArchiveDate: null,
    }
  },
  props: {
    division: {
      default: 4,
      type: Number,
    },
  },
  emits: ['changeDivision', 'clearFilter'],
  created() {
    this.startDate = this.getLastDate()
    this.getMultiVideoData()

    // ライブ、過去映像両方の閲覧権限がある場合のみ、storeの情報を利用する
    if (this.permissions.live && this.permissions.archive) {
      this.useLive = this.liveState
    } else {
      this.useLive = this.permissions.live
    }
  },
  unmounted() {
    // 画面が切り替わるタイミングでイベントを削除する
    document.body.removeEventListener('keyup', this.exitFullScreen)
  },
  computed: {
    ...mapGetters({
      permissions: 'auth/permissions',
      cameraInfo: 'detail/cameraInfo',
      liveState: 'multiVideos/liveState',
    }),
    /**
     * 現在表示している過去映像の日時
     */
    currentDate() {
      const date = formatDateStr(this.displayDate, 'YYYY/MM/DD HH:mm')
      return `${date}:${formatSecondStr(this.archiveDisplayTime).slice(-2)}`
    },
    /**
     * ゼロ件表示
     */
    isZeroItem() {
      return this.totalCameras === 0
    },
    /**
     * 過去映像の表示日時
     */
    archiveStartTime() {
      const date = new Date(`${getDateStr(this.startDate.date)} ${this.startDate.time}`)
      return getIsoDateStr(date)
    },
    /**
     * 画面に表示する映像の再生日時
     */
    displayDate() {
      const date = formatDateStr(this.startDate.date, 'YYYY-MM-DD')
      const time = this.startDate.time
      return getIsoDateStr(`${date} ${time}`)
    },
  },
  methods: {
    /**
     * マルチ映像の用のデータを取得
     */
    getMultiVideoData() {
      this.pageSize = this.division
      this.isPaused = true
      this.isArchiveLoaded = false

      this.getCameras().then(() => {
        /**
         * 分割数分のカメラの過去映像を取得
         */
        if (this.permissions.archive) {
          this.getArchives()
        } else {
          // 過去映像の閲覧権限が無い場合は実行しない
          this.cameras = this.cameraList
          this.isArchiveLoaded = true
        }
      })
    },
    /**
     * 過去映像の取得
     */
    getArchives() {
      // getMultiVideoDataでもisArchiveLoadedの設定を行っているが、
      // getArchives単体で利用される場合もあるのでこちらでも設定する
      this.isArchiveLoaded = false
      this.displayCameraData = []

      const date = formatDateStr(this.startDate.date, 'YYYY-MM-DD')
      const time = this.startDate.time
      this.streamArchiveDate = getIsoDateStr(`${date} ${time}`)

      this.isPaused = true
      Promise.all(
        this.cameraList.map((d) => {
          this.displayCameraData.push(d)
          return this.requestArchives(d.camera_code, date, time)
        })
      ).then((res) => {
        // 取得したデータの中から、該当の日時の過去映像を取得
        this.archiveData = res.map((d) => d.data.data)
        this.cameras = this.formatArchivesData(this.archiveData)

        if (this.useLive) {
          // ライブ配信
          this.isArchiveLoaded = true
        } else {
          // 過去映像表示
          this.showArchive()
        }
      })
    },
    /**
     * 過去映像の表示
     */
    showArchive() {
      // タイムラプスのカメラの場合はデータありとする
      if (
        this.cameras.find((d) => d.has_data || d.use_time_lapse) ||
        this.archiveRetryCount >= maxArchiveRetryPlayCount
      ) {
        this.isArchiveLoaded = true
        this.archiveRetryCount = 0
      } else {
        // 表示時間のデータが取得できない場合はリトライする
        this.retryRequestArchive()
      }
    },
    /**
     * 過去映像の再取得(AWSの署名有効期限ぎれ対応にも利用)
     */
    retryRequestArchive() {
      const interval = archivePlayRetryIntervalSec * 2 ** this.archiveRetryCount * 1000
      this.archiveRetryCount++
      // 過去映像を読み込み
      this.archivePlayRetryTimeoutId = setTimeout(this.getArchives, interval)
    },
    /**
     * 過去映の取得用のリクエスト
     * @param {String} cameraCode カメラコード
     * @param {String} date 取得する日付の文字列
     * @param {String} time 取得する時分の文字列
     * @return {Promise} 過去映像取得用のリクエスト
     */
    requestArchives(cameraCode, date, time) {
      // 分が00ではない場合エラーとなるので00にフォーマット
      const t = `${time.split(':')[0]}:00`
      const archivesApi = API.CAMERA_ARCHIVES.replace('{camera_code}', cameraCode)
      // start_dateはYYYY-MM-DD HH:00形式で渡す

      const params = {
        start_date: getIsoDateStr(`${date} ${t}`),
        period: 1,
      }

      // 処理待ちのリクエストをキャンセルする(連続実行された場合に最後のリクエストのみ実行する)
      // 連続実行されるとvideoのロードが複数回行われ、停止状態になる場合がある為
      http.cancel(`${archivesApi}_get`)

      return http.get(archivesApi, params)
    },
    /**
     * 過去映の中から該当の時間の過去映像を取得して、カメラの詳細情報を追加。
     * @param {Array} cameras 過去映像のデータ一覧
     * @return {Object} 該当日付の過去映像のデータ一覧
     */
    formatArchivesData(cameras) {
      this.streamArchiveDate = this.displayDate
      // 過去映像一覧のデータの中から、表示時間のデータを取得
      return cameras.map((data, index) => {
        const cameraData = data.find((d) => {
          return d.start_time === this.displayDate
        })

        const camera = this.displayCameraData[index]

        // 過去映像のデータを拡張
        return Object.assign(cameraData, camera)
      })
    },
    /**
     * 過去映の表示時間の制御
     */
    setArchiveDisplayTime() {
      setTimeout(() => {
        this.archiveDisplayTime = this.videoElm.currentTime

        if (this.archiveDisplayTime < this.duration && this.isPaused === false) {
          this.setArchiveDisplayTime()
        } else if (this.archiveDisplayTime >= this.duration) {
          // 最後まで再生された場合は停止して、次の過去映像情報を取得
          this.isPaused = true
          // 時間をまたぐ場合は再度データを取得する
          if (getDateObject(this.displayDate).getMinutes() === 59) {
            this.getNextHourArchives()
            return
          } else {
            this.getNextMinuteArchives()
          }
          this.autoPlayCount++
        }
      }, archiveIntervalTime)
    },
    /**
     * 1分後の過去映像を表示
     */
    getNextMinuteArchives() {
      const nextTime = getAddTime(this.displayDate, 1, 'minutes')

      // startDateに設定
      this.startDate.date = nextTime
      this.startDate.time = `${nextTime.getHours()}:${nextTime.getMinutes()}`

      // 表示時間を変更

      this.archiveDisplayTime = 0
      // 過去映像を入れ替える
      this.cameras = this.formatArchivesData(this.archiveData)
      // 1分後の分割数分全て過去映像データがない場合は過去映像を再取得する
      if (!this.cameras.find((d) => d.has_data)) {
        this.isArchiveLoaded = false
        this.retryRequestArchive()
      }
    },
    /**
     * 次の時間の過去映像を表示(HH:00で取得)
     */
    getNextHourArchives() {
      const nextTime = getAddTime(this.displayDate, 1, 'hours')

      // startDateに設定
      this.startDate.date = nextTime
      this.startDate.time = `${nextTime.getHours()}:00`

      // 過去映像を取得
      this.getArchives()
    },
    /**
     * 過去映像が全てロードされたら実行される
     * @param {Object} data video elementとvideoのロードに1つでも成功しているかのフラグ
     */
    loadedArchiveData(data) {
      this.videoElm = data.videoElm
      this.duration = this.videoElm.duration

      // 現在の再生時間の制御
      this.archiveDisplayTime = this.videoElm.currentTime
      // 連続自動再生は60回(60分）まで。コスト的な問題で60回再生したら停止する。
      if (autoPlayMaxCount > this.autoPlayCount) {
        this.playVideo()
      } else {
        this.autoPlayCount = 0
      }
    },
    /**
     * 過去映像最新映像日時を取得(現在時刻より2分前をセット)。
     */
    getLastDate() {
      const date = getAddTime(new Date(), -2, 'minute')
      return { date: date, time: getTimeStr(date) }
    },
    /**
     * 速度、分割数などのナビゲーションの表示切り替え
     */
    toggleNav() {
      this.isShowToolTip = !this.isShowToolTip
    },
    /**
     * ページの変更
     * @param {Number} page 表示するページ番号
     */
    pageChange(page) {
      this.currentPage = page
      this.getMultiVideoData()
    },
    /**
     * 再生スピードの変更
     * @param {Number} v 再生スピード
     */
    changePlaySpeed(v) {
      this.speed = v
    },
    /**
     * 分割数の変更
     * @param {Number} v 分割数
     */
    changeDivision(v) {
      this.currentPage = 1
      this.$emit('changeDivision', v)
    },
    /**
     * 映像の再生
     */
    playVideo() {
      this.isPaused = false
      this.setArchiveDisplayTime()
    },
    /**
     * 映像の再生、停止の制御
     */
    togglePlayPause() {
      this.isPaused = !this.isPaused
      this.setArchiveDisplayTime()
    },
    /**
     * 映像コントローラーの表示
     * フルスクリーンの場合無操作だったらコントローラを非表示にする
     */
    showController() {
      if (this.keepControllerFlag) {
        return
      }
      this.isShowController = true
      clearTimeout(this.mouseActionTimeout)
      this.mouseActionTimeout = setTimeout(() => {
        this.hideController()
      }, deactivateTime)
    },
    showKeepController() {
      this.keepControllerFlag = true
      this.isShowController = true
      clearTimeout(this.mouseActionTimeout)
    },
    doNotKeepController() {
      this.keepControllerFlag = false
    },
    /**
     * 映像コントローラーの非表示
     */
    hideController() {
      this.isShowController = false
      this.isShowToolTip = false
    },
    /**
     * seekBarを動かした場合の制御
     */
    currentTimeChange() {
      this.seekBarValue = this.archiveDisplayTime
    },
    /**
     * 過去映像の表示日付の変更
     */
    changeDisplayDate() {
      if (this.useLive) {
        // useLiveが変更されば場合は過去映像を取得するのでgetArchivesを実行しない
        this.useLive = false
      } else {
        this.getArchives()
      }
    },
    /**
     * フルスクリーン表示、解除
     */
    toggleFullScreen() {
      if (this.fullScreenFlag) {
        exitFullScreen(this.exitFullScreen)
      } else {
        fullScreen(this.$refs.multiVideos, this.exitFullScreen)
      }

      this.fullScreenFlag = !this.fullScreenFlag
    },
    /**
     * フルスクリーンの解除
     */
    exitFullScreen() {
      // フルスクリーンのDOMがある場合のみ実行
      if (!documentGetFullscreenElement()) {
        this.fullScreenFlag = false
      }
    },
    /**
     * 最新の映像を表示
     */
    showLastArchive() {
      this.startDate = this.getLastDate()
      this.getArchives()
    },
  },
  watch: {
    useLive(value, oldValue) {
      // 初期化時の値変更は処理を実行しない
      if (oldValue === null) {
        return
      }

      // ライブ、過去映像の表示状態をstoreに保存
      this.$store.commit('multiVideos/setLiveState', value)

      if (value) {
        this.isPaused = true
      } else {
        this.getArchives()
      }
    },
    division() {
      this.getMultiVideoData()
    },
  },
}
</script>

<style scoped lang="scss">
.player {
  position: relative;
  padding: $size-s;
  overflow: hidden;
}

.player_wrap {
  position: relative;
  background: $color-dark1;
}

.speed_tooltip {
  position: absolute;
  bottom: 60px;
  right: $size-m;
  z-index: 10;
}

.currentDate {
  position: absolute;
  top: $size-m;
  left: $size-m;
  @include fs($font-size-5);
  color: $color-white;
  font-weight: bold;
  text-shadow: 0 0 3px $color-dark1;
}

.full_screen {
  height: 100vh;
  ::v-deep(.white_box) {
    border-radius: 0;
  }
}

@include fade_animation($duration-fast);
</style>
