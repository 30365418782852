import i18n from '@/plugins/i18n'
import store from '@/store'
import { displayNotificationAnnouncement } from '@/js/utils/environment'

export default {
  APP_NAME: 'CiaoCameraApp',
  APP_VERSION: process.env.VUE_APP_VERSION || 'SNAPSHOT',
  PAGE_LIMIT: 50,
  NOTIFICATIONS_LIMIT: 20,
  STREAMING_EXPIRES_ARCHIVE: 3600,
  STREAMING_EXPIRES_LIVE: 43200,
  ARCHIVE_DISPLAY_PERIOD: [
    {
      value: 1,
      label: '1H',
      lines: 10, // timelineに表示するラインの数
    },
    {
      value: 6,
      label: '6H',
      lines: 6,
    },
    {
      value: 24,
      label: '24H',
      lines: 12,
    },
  ],
  FUNC: (environmentMode) => {
    const func = [
      { value: 'motion', name: i18n.global.t('app_js.motion_detection') },
      // 迷惑駐車は未リリース機能なのでコメントアウト
      // { value: 'parking_detect', name: i18n.global.t('app_js.illegal_parking') },
      { value: 'heatmap', name: i18n.global.t('app_js.heatmap') },
      { value: 'live', name: i18n.global.t('app_js.live_video') },
      { value: 'audio', name: i18n.global.t('app_js.voice') },
      { value: 'people_counter', name: i18n.global.t('app_js.people_counter') },
      { value: 'ptz', name: i18n.global.t('app_js.ptz') },
      { value: 'time_lapse', name: i18n.global.t('app_js.time_lapse') },
    ]

    // TLTは「侵入検知、人物追従、360度表示」を追加
    if (environmentMode.startsWith('TLT')) {
      func.push({ value: 'enter_alert', name: i18n.global.t('app_js.enter_alert') })
      func.push({ value: 'person_tracking', name: i18n.global.t('app_js.person_tracking') })
      func.push({ value: 'dewarp', name: i18n.global.t('app_js.dewarp') })
      func.push({ value: 'operation_analysis', name: i18n.global.t('app_js.operation_analysis') })
    }

    return func
  },
  DOWN: [{ value: 'down', name: i18n.global.t('app_js.down') }],
  EVENT_LIST: () => {
    const list = [
      { value: 'all', label: i18n.global.t('app_js.all') },
      { value: 'motionDetect', label: i18n.global.t('app_js.with_motion') },
      { value: 'peopleCounter', label: i18n.global.t('app_js.with_people_counter') },
    ]

    if (store.getters['app/isTLT']) {
      list.push({ value: 'enterAlert', label: i18n.global.t('app_js.with_enter_alert') })
    }

    return list
  },
  WEATHER_TYPE: [
    {
      value: 0,
      label: i18n.global.t('app_js.please_select'),
      subtypes: null,
    },
    {
      value: 1,
      label: i18n.global.t('app_js.all_warnings'),
      subtypes: null,
    },
    {
      value: 2,
      label: i18n.global.t('app_js.special_warning_purple'),
      subtypes: [
        { value: 'emergency_warning', label: i18n.global.t('app_js.special_warning') },
        { value: 'emergency_warning_32', label: i18n.global.t('app_js.blizzard_special_warning') },
        {
          value: 'emergency_warning_33',
          label: i18n.global.t('app_js.heavy_rain_special_warning'),
        },
        { value: 'emergency_warning_35', label: i18n.global.t('app_js.windstorm_special_warning') },
        {
          value: 'emergency_warning_36',
          label: i18n.global.t('app_js.heavy_snow_special_warning'),
        },
        { value: 'emergency_warning_37', label: i18n.global.t('app_js.wave_special_warning') },
        { value: 'emergency_warning_38', label: i18n.global.t('app_js.high_tide_special_warning') },
      ],
    },
    {
      value: 3,
      label: i18n.global.t('app_js.warning_red'),
      subtypes: [
        { value: 'warning', label: i18n.global.t('app_js.warning') },
        { value: 'warning_02', label: i18n.global.t('app_js.blizzard_warning') },
        { value: 'warning_03', label: i18n.global.t('app_js.heavy_rain_warning') },
        { value: 'warning_04', label: i18n.global.t('app_js.flood_warning') },
        { value: 'warning_05', label: i18n.global.t('app_js.storm_warning') },
        { value: 'warning_06', label: i18n.global.t('app_js.heavy_snow_warning') },
        { value: 'warning_07', label: i18n.global.t('app_js.wave_warning') },
        { value: 'warning_08', label: i18n.global.t('app_js.high_tide_warning') },
      ],
    },
    {
      value: 4,
      label: i18n.global.t('app_js.advisory_yellow'),
      subtypes: [
        { value: 'advisory', label: i18n.global.t('app_js.advisory') },
        { value: 'advisory_10', label: i18n.global.t('app_js.heavy_rain_advisory') },
        { value: 'advisory_12', label: i18n.global.t('app_js.heavy_snow_advisory') },
        { value: 'advisory_13', label: i18n.global.t('app_js.snowstorm_advisory') },
        { value: 'advisory_14', label: i18n.global.t('app_js.thunder_advisory') },
        { value: 'advisory_15', label: i18n.global.t('app_js.strong_wind_advisory') },
        { value: 'advisory_16', label: i18n.global.t('app_js.wave_advisory') },
        { value: 'advisory_17', label: i18n.global.t('app_js.snow_melting_advisory') },
        { value: 'advisory_18', label: i18n.global.t('app_js.flood_advisory') },
        { value: 'advisory_19', label: i18n.global.t('app_js.high_tides_advisory') },
        { value: 'advisory_20', label: i18n.global.t('app_js.dense_fog_advisory') },
        { value: 'advisory_21', label: i18n.global.t('app_js.dry_advisory') },
        { value: 'advisory_22', label: i18n.global.t('app_js.avalanches_advisory') },
        { value: 'advisory_23', label: i18n.global.t('app_js.low_temperature_advisory') },
        { value: 'advisory_24', label: i18n.global.t('app_js.frost_advisory') },
        { value: 'advisory_25', label: i18n.global.t('app_js.icing_advisory') },
        { value: 'advisory_26', label: i18n.global.t('app_js.snow_accretion_advisory') },
        { value: 'advisory_27', label: i18n.global.t('app_js.other_advisories') },
      ],
    },
  ],
  PLAY_SPEED_OPTIONS: [
    {
      label: 1,
      text: i18n.global.t('app_js.standard'),
    },
    {
      label: 2,
      text: '2' + i18n.global.t('app_js.times'),
    },
    {
      label: 4,
      text: '4' + i18n.global.t('app_js.times'),
    },
    {
      label: 8,
      text: '8' + i18n.global.t('app_js.times'),
    },
    {
      label: 12,
      text: '12' + i18n.global.t('app_js.times'),
    },
  ],
  MULTI_VIDEO_DIVISION_OPTIONS: [
    {
      label: 2,
      text: '2',
      row: 1,
    },
    {
      label: 4,
      text: '4',
      row: 2,
    },
    {
      label: 6,
      text: '6',
      row: 2,
    },
    {
      label: 8,
      text: '8',
      row: 2,
    },
    {
      label: 9,
      text: '9',
      row: 3,
    },
    {
      label: 12,
      text: '12',
      row: 3,
    },
    {
      label: 16,
      text: '16',
      row: 4,
    },
  ],
  SELECT_LANGUAGE_OPTIONS: [
    { value: 'ja', label: '日本語' },
    { value: 'en', label: 'English' },
  ],
  MAP_TYPE: [
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
  NOTIFICATION_MESSAGES: {
    Download: i18n.global.t('header_nav_main.download_notification'),
    EnterAlert: i18n.global.t('header_nav_main.enter_notification'),
    Motion: i18n.global.t('header_nav_main.motion_notification'),
    Monitor: i18n.global.t('header_nav_main.monitor_notification'),
    PersonTracking: i18n.global.t('header_nav_main.person_tracking'),
    OperationAnalysis: i18n.global.t('header_nav_main.operation_analysis'),
  },
  GET_NOTIFICATIONS_TABS: (environmentMode) => {
    const tabs = []
    if (displayNotificationAnnouncement(environmentMode)) {
      // L21は「Ciao Cameraから」のお知らせを利用しない
      tabs.push({ label: i18n.global.t('notifications.ciao_camera'), key: 'Announcement' })
    }
    tabs.push({ label: i18n.global.t('notifications.motion_notification'), key: 'Motion' })

    if (environmentMode.startsWith('TLT')) {
      tabs.push({ label: i18n.global.t('notifications.enter_alert'), key: 'EnterAlert' })
      tabs.push({ label: i18n.global.t('notifications.person_tracking'), key: 'PersonTracking' })
      tabs.push({
        label: i18n.global.t('notifications.operation_analysis'),
        key: 'OperationAnalysis',
      })
    }

    tabs.push({ label: i18n.global.t('notifications.monitor'), key: 'Monitor' })
    tabs.push({ label: i18n.global.t('notifications.download_all'), key: 'Download' })

    return tabs
  },
  PUSH_SUBSCRIPTION_EXPIRES: 60 * 1000 * 11,
  // PushSubscriptionの有効期限より少し短くする
  PUSH_SUBSCRIPTION_EXPIRES_UPDATE_INTERVAL: 60 * 1000 * 10,
  ENTRY_REPORT_CHART_TYPE_OPTIONS: [
    { value: 'Stack', label: i18n.global.t('entry_detection_report.stack') },
    { value: 'set', label: i18n.global.t('entry_detection_report.set') },
  ],
  ENTRY_HISTORY_TIME_ORDER_OPTIONS: [
    { value: '1Minute', label: '1' + i18n.global.t('entry_detection_report.minute') },
    { value: '5Minute', label: '5' + i18n.global.t('entry_detection_report.minute') },
    { value: '10Minute', label: '10' + i18n.global.t('entry_detection_report.minute') },
    { value: '30Minute', label: '30' + i18n.global.t('entry_detection_report.minute') },
  ],
  CONTINUE_TIME_ORDER_OPTIONS: [
    {
      value: '1',
      label: i18n.global.t('entry_detection_report.more_than_minute', { minutes: 1 }),
    },
    {
      value: '5',
      label: i18n.global.t('entry_detection_report.more_than_minute', { minutes: 5 }),
    },
  ],
  // 本番環境では利用しないので多言語化は不要
  EXPERIMENTAL_FEATURES: [{ value: 'lighting', label: 'カメラ付き照明' }],
  ENVIRONMENT_MODE: [
    { value: '', label: '通常' },
    { value: 'L21', label: 'L21' },
    { value: 'TLT:3', label: 'TLT' },
  ],
}
