import router from '@/router'
import http from '@/js/utils/http'
import API from '@/js/const/api'
import { isSameTime, getCurrentDate, getDateObject, getIsoDateStr } from '@/js/utils/date'
import { deepCopy } from '@/js/utils/util'
import { checkRecordRange } from '@/js/utils/cameraDetail'

const defaultValue = {
  selectedEventFilter: 'motionDetect',
  eventToolTipState: true,
  useCameraInfo: true, // TODO あとで消す
  useFullSizeVideo: false,
  liveState: false,
  cameraInfo: {
    showDate: true, // 日付表示
    showMotionDetectArea: true, // モーション検知エリア表示
    showMotionDetectAnalysisInfo: true, // モーション検知+物体検知情報表示
    showEnterDetectArea: true, // 侵入検知エリア表示
    showOperationAnalysisArea: true, // 作業分析エリア表示
  },
}

const state = {
  // 選択中(再生する)過去映像
  selectedArchive: null,
  timeLineScrollVal: 0,
  moveTarget: null,
  soundVolume: 0.5,
  archiveVideoElm: null,
  //  アプリの方針として、「動きのない映像は見せない」なので、
  // イベントの絞り込みの初期値は、「モーション検知あり」にする。
  selectedEventFilter: defaultValue.selectedEventFilter,
  eventToolTipState: defaultValue.eventToolTipState,
  useCameraInfo: defaultValue.useCameraInfo, // TODO あとで消す
  cameraInfo: defaultValue.cameraInfo,
  useFullSizeVideo: defaultValue.useFullSizeVideo,
  liveState: defaultValue.liveState,
  archives: null,
  cameraDetail: null,
  displaySeconds: null,
  videoHeight: null,
}

const mutations = {
  // 選択中(再生する)の過去映像をセット
  setSelectedArchive(state, archive) {
    state.selectedArchive = archive || null
  },
  // 選択中(再生する)の過去映像をリセット
  resetSelectedArchive(state) {
    state.selectedArchive = null
  },
  setArchives(state, payload) {
    state.archives = payload
  },
  resetActives(state) {
    state.archives = null
  },
  setTimeLineScrollVal(state, payload) {
    state.timeLineScrollVal = payload
  },
  setScrollTarget(state, payload) {
    state.moveTarget = payload
  },
  setSoundVolume(state, payload) {
    state.soundVolume = payload
  },
  setArchiveVideoElm(state, payload) {
    state.archiveVideoElm = payload
  },
  setEventFilter(state, payload) {
    state.selectedEventFilter = payload
  },
  // TODO 後で消す
  setCameraInfoState(state, payload) {
    state.useCameraInfo = payload
  },
  setCameraInfo(state, payload) {
    state.cameraInfo = payload
  },
  setFullWidthVideoState(state, payload) {
    state.useFullSizeVideo = payload
  },
  setCameraDetail(state, detailData) {
    state.cameraDetail = detailData
  },
  resetCameraDetail(state) {
    state.cameraDetail = null
  },
  setDisplaySeconds(state, payload) {
    state.displaySeconds = payload
  },
  resetDisplaySeconds(state) {
    state.displaySeconds = null
  },
  setVideoHeight(state, height) {
    state.videoHeight = height
  },
  setLiveState(state, liveState) {
    state.liveState = liveState
  },
  setEventToolTipState(state, toolTipState) {
    state.eventToolTipState = toolTipState
  },
  // 設定を初期値にクリア
  resetSetting(state) {
    // 映像詳細の過去映像一覧の絞り込み
    state.selectedEventFilter = defaultValue.selectedEventFilter
    // 映像プレイヤー上に表示する情報の表示
    state.useCameraInfo = defaultValue.useCameraInfo // TODO 後で消す
    state.cameraInfo = defaultValue.cameraInfo
    // 最大幅表示
    state.useFullSizeVideo = defaultValue.useFullSizeVideo
    // 映像詳細のライブ、過去映像の表示
    state.liveState = defaultValue.liveState
    // 絞り込みイベントのセレクトボックス上にだすツールチップの表示状態
    state.eventToolTipState = defaultValue.eventToolTipState
  },
}

const getters = {
  moveTarget(state) {
    return state.moveTarget
  },
  soundVolume(state) {
    return state.soundVolume
  },
  timeLineScrollVal(state) {
    return state.timeLineScrollVal
  },
  archiveVideoElm(state) {
    return state.archiveVideoElm
  },
  // 選択中(再生する)の過去映の再生日時
  selectedArchiveStartTime(state) {
    return state.selectedArchive ? state.selectedArchive.start_time : null
  },
  // 選択中(再生する)の過去映のデータのパス
  selectedArchiveDataUrl(state) {
    return state.selectedArchive ? state.selectedArchive.data_url : null
  },
  // 選択中(再生する)の過去映のイベント情報
  selectedArchiveEvents(state) {
    let res = {}

    if (state.selectedArchive && state.selectedArchive.events) {
      res = state.selectedArchive.events
    }
    return res
  },
  // 選択中のイベントでの絞り込み
  selectedEventFilter(state) {
    let event = 'all'
    // TODO Vue3 update pages/Detail/main.vue unmounted時にもPlayerContainerのwatchが実行されエラーになるようになった為対策
    if (!state.cameraDetail) {
      return event
    }
    if (
      (state.selectedEventFilter === 'motionDetect' &&
        (state.cameraDetail.use_motion || state.cameraDetail.use_camera_motion)) ||
      (state.selectedEventFilter === 'peopleCounter' && state.cameraDetail.use_people_counter) ||
      (state.selectedEventFilter === 'enterAlert' && state.cameraDetail.use_enter_alert)
    ) {
      event = state.selectedEventFilter
    }

    return event
  },
  // TODO あとで消す
  useCameraInfo(state) {
    return state.useCameraInfo
  },
  cameraInfo(state) {
    return state.cameraInfo
  },
  useFullSizeVideo(state) {
    return state.useFullSizeVideo
  },
  archives(state) {
    if (!state.archives) {
      return state.archives
    }

    if (!state.cameraDetail) {
      return null
    }

    let archives = deepCopy(state.archives)
    // 録画期間内かのフラグをセットする
    archives = archives.map((d) => {
      d.inRecordRange = checkRecordRange(d.start_time, state.cameraDetail)
      return d
    })

    // 未来日データを追加
    archives = setFutureState(archives)

    //  モーション検知のみの場合
    if (
      state.cameraDetail.archive_hide &&
      state.cameraDetail.archive_hide.includes('no_motion') &&
      (state.cameraDetail.use_motion || state.cameraDetail.use_camera_motion)
    ) {
      archives = archives.filter((d) => d.motion_state)
    } else {
      // モーション検知(カメラ搭載も含む)
      if (
        state.selectedEventFilter === 'motionDetect' &&
        (state.cameraDetail.use_motion || state.cameraDetail.use_camera_motion)
      ) {
        archives = archives.filter((d) => d.motion_state)
      }
      // 人数カウント
      if (state.selectedEventFilter === 'peopleCounter' && state.cameraDetail.use_people_counter) {
        archives = archives.filter(
          (d) => d.events && d.events.peopleCounter.findIndex((d) => d.inOut === 'I') >= 0
        )
      }
      // 侵入検知
      if (state.selectedEventFilter === 'enterAlert' && state.cameraDetail.use_enter_alert) {
        archives = archives.filter((d) => d.events && d.events.enterAlert.length > 0)
      }
    }

    return archives
  },
  allArchives(state) {
    return state.archives
  },
  // カメラ詳細を取得
  cameraDetail(state) {
    return state.cameraDetail
  },
  // 取得した過去映像一覧の中に有効なデータがあるかの判定
  hasValidArchiveData(state) {
    if (!state.archives) {
      return false
    }

    return state.archives.find((d) => d.has_data === true) !== undefined
  },
  displaySeconds(state) {
    return state.displaySeconds
  },
  // videoの原寸からの拡大率(zoom率ではない)
  videoDisplayScale(state) {
    if (!state.cameraDetail) {
      return 1
    }

    return state.videoHeight / state.cameraDetail.image_rs_height
  },
  // videoの幅を取得
  videoWidth(state) {
    const aspect = state.cameraDetail.image_rs_width / state.cameraDetail.image_rs_height
    return state.videoHeight * aspect
  },
  // ライブの表示状態
  liveState(state) {
    return state.liveState
  },
  //  絞り込みのイベントtooltipの表示状態
  eventToolTipState(state) {
    return state.eventToolTipState
  },
}

const actions = {
  /**
   * カメラ詳細を取得
   * @param {String} cameraCode カメラコード
   */
  async getCameraDetail({ commit }, cameraCode) {
    try {
      // データをリセット
      commit('resetCameraDetail')
      const api = API.CAMERA_DETAIL.replace('{camera_code}', cameraCode)
      const res = await http.get(api)
      commit('setCameraDetail', res.data.data)
    } catch (error) {
      if (error.response.status === 404) {
        router.push({
          name: 'notfound',
        })
      }
    }
  },
  /**
   * 過去映像一覧を取得
   * @param {Object} params リクエストパラメータ
   * @param {String} cameraCode カメラコード
   */
  async getArchives({ commit }, { params, cameraCode }) {
    const archivesApi = API.CAMERA_ARCHIVES.replace('{camera_code}', cameraCode)
    const res = await http.get(archivesApi, params)
    commit('setArchives', res.data.data)
  },
  /**
   * 過去映像一覧を更新(直近の1時間分のみ）
   * @param {String} cameraCode カメラコード
   */
  async updateArchives({ commit, state }, { cameraCode }) {
    let archives = deepCopy(state.archives)
    archives = setFutureState(archives)
    // 表示中の過去映像一覧で、未来日データがある場合は過去映像一覧を更新する
    const futureData = archives.find((d) => d.isFuture)

    // 未来日データがなければ更新処理は行わない
    if (!futureData) {
      return Promise.resolve()
    }

    const startDate = getIsoDateStr(futureData.start_time, 'YYYY-MM-DD HH:00')
    const archivesApi = API.CAMERA_ARCHIVES.replace('{camera_code}', cameraCode)

    const params = {
      start_date: startDate,
      period: 1, // 最新の1時間分のみ取得なので1で固定
    }

    const res = await http.get(archivesApi, params)
    // startDateの時間帯の過去映像は更新データで置き換えるので削除
    archives = archives.filter(
      (d) => !isSameTime(startDate, getIsoDateStr(d.start_time, 'YYYY-MM-DD HH:00'))
    )

    // 更新分の過去映像と、更新不要分の過去映像を連結する
    archives = archives.concat(res.data.data)

    // 日付順でソートする
    archives.sort((a, b) => new Date(a.start_time) - new Date(b.start_time))

    // 過去映像をセット
    commit('setArchives', archives)
  },
}

/**
 * 過去映像一覧に未来日データをセット
 * @param {Array} 過去映像一覧
 * @return {Array} 未来日データをセットした過去映像一覧
 */
function setFutureState(archives) {
  // 過去映像一覧の中で最新の有効なデータ(has_data = true)のものを抽出するのでreverseしている
  const validArchiveData = deepCopy(archives)
    .reverse()
    .find((archive) => {
      return archive.has_data
    })
  // 表示時刻の30分前まで未来日とする
  const currentTime = getDateObject(getCurrentDate('YYYY-MM-DD HH:mm'))
  const futureDateThreshold = currentTime - 60 * 1000 * 30
  return archives.map((archive) => {
    const isFutureDate = getDateObject(archive.start_time) >= futureDateThreshold
    // 対象の過去映像より先の時間に有効な過去映像があるか
    const hasFutureData = validArchiveData
      ? getDateObject(validArchiveData.start_time) > getDateObject(archive.start_time)
      : false

    /**
     * サムネイルの未来日表示の条件
     * ・映像データがない
     * ・表示時刻より30分以内
     * ・対象の過去映像よりも先(未来）に有効なデータがない
     */
    archive.isFuture = !archive.has_data && isFutureDate && !hasFutureData
    return archive
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
