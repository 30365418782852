const state = {
  sort: 'asc',
}

const mutations = {
  setSort(state, sort) {
    state.sort = sort
  },
  resetSetting(state) {
    state.sort = 'asc'
  },
}

const getters = {
  sort(state) {
    return state.sort
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
