/**
 * PC、タブレット、スマホ判定用の関数です(画面大きさによる)
 * @param environmentMode 環境情報
 * @return サイドナビのリリース情報の表示・非表示
 */
export function displayReleasesNav(environmentMode: string): boolean {
  // TLT、L21環境では表示しない
  return !(environmentMode === 'L21' || environmentMode.startsWith('TLT'))
}

/**
 * 「チャオからのお知らせ」の表示
 * @param environmentMode 環境情報
 * @return チャオからのお知らせを表示する場合trueを返却
 */
export function displayNotificationAnnouncement(environmentMode: string): boolean {
  // L21環境では表示しない
  return environmentMode !== 'L21'
}
