import { defineRule, configure } from 'vee-validate'
import AllRules from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'
import store from '@/store'
import en from '@vee-validate/i18n/dist/locale/en'
import ja from '@vee-validate/i18n/dist/locale/ja'

// TODO Vue3 update とりあえず全部追加する
Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule])
})

// 小数点チェックのカスタムルール
defineRule('decimal', (value, params, meta) => {
  const numbers = String(value).split('.')
  const checkLength = Number(params[0]) || 0
  const message = {
    ja: `${meta.field}は小数第${params[0]}位以内で入力してください`,
    en: `Please enter ${meta.field} to the decimal ${params[0]} place`,
  }

  if (!numbers[1] || numbers[1].length <= checkLength) {
    return true
  }

  return message[store.getters['app/langInfo']]
})

configure({
  generateMessage: localize({
    en,
    ja,
  }),
})

setLocale(store.getters['app/langInfo'])
