import axios from 'axios'
import router from '@/router'
import store from '@/store'
import i18n from '@/plugins/i18n'
import { ElMessage } from 'element-plus'
import { getOsName } from '@/js/utils/util'
import live from '@/js/utils/live'

// 共通エラーメッセージの重複表示制御用フラグ
let showErrorMessage = false

const endpoint = process.env.VUE_APP_ENDPOINT
const http = axios.create({
  // Enable Cross-Origin Cookies (デバッグ時のみ)
  withCredentials: process.env.NODE_ENV !== 'production',
  validateStatus: (status) => {
    // 認証エラーの場合は、storeのstateを更新してlogin画面に戻す
    if (status === 401) {
      store.commit('auth/clearAuthInfo')
      if (router.currentRoute.value.name !== 'login') {
        router.push({ name: 'login', query: { redirect: router.currentRoute.value.fullPath } })
      }
      return false
    }

    // 500、501、502、503、504、505の場合
    if (status >= 500) {
      showCommonError()
      return false
    }

    if (status !== 200) {
      return false
    }

    return true
  },
})

// リクエストキャンセル用データ格納用
const sources = {}

/**
 * リクエスト成功時の処理
 * @param {Object} response APIレスポンス
 */
const onSuccess = (response) => {
  return Promise.resolve(response)
}

/**
 * リクエスト失敗時の処理
 * @param {Object} error APIレスポンス
 */
const onError = (error) => {
  // 通信をキャンセルした場合はエラーをださない
  if (!error.response && error.message === 'Network Error') {
    showCommonError()
  }

  return Promise.reject(error)
}

/**
 * リクエスト前の処理
 */
http.interceptors.request.use((config) => {
  // 多言語対応用
  config.headers['Accept-Language'] = store.getters['app/langInfo']
  config.headers['Accept'] = 'application/json'
  return config
})

/**
 * レスポンス前の処理
 */
http.interceptors.response.use((response) => {
  return response
})

/**
 * リクエストキャンセル用のデータを生成してCancelTokenを返却
 * @param {String} sourceKey リクエストキャンセル用のキー
 */
const getCancelToken = (sourceKey) => {
  // リクエストキャンセル用のsourceデータをセット
  sources[sourceKey] = axios.CancelToken.source()
  return sources[sourceKey].token
}

/**
 * 共通エラー
 */
const showCommonError = () => {
  if (!showErrorMessage) {
    showErrorMessage = true
    ElMessage({
      message: i18n.global.t('http.common_error'),
      type: 'error',
      duration: '5000',
      onClose: () => {
        showErrorMessage = false
      },
    })
  }
}

export default {
  get: (url, params, headers = {}, responseType) => {
    const cancelToken = getCancelToken(`${url}_get`)
    const requestUrl = url.startsWith('http') ? url : `${endpoint}${url}`

    return http
      .get(requestUrl, {
        params,
        headers,
        responseType,
        cancelToken,
      })
      .then(onSuccess)
      .catch(onError)
  },
  post: (url, params, headers = {}, responseType) => {
    const cancelToken = getCancelToken(`${url}_post`)

    return http
      .post(`${endpoint}${url}`, params, { headers, responseType, cancelToken })
      .then(onSuccess)
      .catch(onError)
  },
  put: (url, params, headers = {}, responseType) => {
    const cancelToken = getCancelToken(`${url}_put`)

    return http
      .put(`${endpoint}${url}`, params, { headers, responseType, cancelToken })
      .then(onSuccess)
      .catch(onError)
  },
  delete: (url, params, headers = {}, responseType) => {
    const cancelToken = getCancelToken(`${url}_delete`)

    return http
      .delete(`${endpoint}${url}`, { params, headers, responseType, cancelToken })
      .then(onSuccess)
      .catch(onError)
  },
  cancel(sourceKey, message = '') {
    if (sources[sourceKey] !== undefined) {
      sources[sourceKey].cancel(message)
    }
  },
  // ライブ配信用
  getLiveImg: (url, config, onLiveSuccess, onLiveFailure, updateInterval, cameraCode) => {
    // ライブ映像は1分間隔でurlが変わり、cancelTokenが溜まってしまうのでカメラコードを利用する
    config.cancelToken = getCancelToken(`${cameraCode}_getLiveImg`)
    // デバッグ時のみ連携されたwithCredentialsを利用
    config.withCredentials = process.env.NODE_ENV !== 'production' ? config.withCredentials : false
    // IEでレスポンスが遅い場合があるのでタイムアウトを設定
    config.timeout = 3000

    // Promise を生成するとすぐに実行が始まるので、関数だけキューイングする。
    const func = (resolve) => {
      // LIVE映像はS3の画像を直接取得する場合もあるのでendpointと連結しない
      http
        .get(`${url}`, config)
        .then(onSuccess)
        .then(onLiveSuccess) // 引数で渡された成功時コールバック
        .catch((error) => {
          // TODO [暫定対応]iOSでのネットワークエラーは画面に表示しない issues/1760の根本解決はできていない
          if (getOsName(window.navigator.userAgent) !== 'iOS') {
            return onError(error)
          } else {
            return Promise.reject(error)
          }
        })
        .catch(onLiveFailure) // 引数で渡された失敗時コールバック
        .finally(() => resolve()) // 成否にかかわらず Promise を完了
    }
    live.addRequest(func)
    // キューの先頭からリクエスト開始
    live.requestNext(updateInterval)
    // Promise を返せないので、コールバック処理は関数を引数でもらう形式
  },
  getWithoutCredentials: (url, config) => {
    return http
      .get(url, Object.assign({ withCredentials: false }, config))
      .then(onSuccess)
      .catch(onError)
  },
}
