const state = {
  displayState: 'normal',
  displayedCoachMark: false,
}

const mutations = {
  setDisplayState(state, displayState) {
    state.displayState = displayState
  },
  setDisplayedCoachMark(state, displayedCoachMark) {
    state.displayedCoachMark = displayedCoachMark
  },
  resetState() {
    state.displayState = 'normal'
  },
}

const getters = {
  displayState(state) {
    return state.displayState
  },
  displayedCoachMark(state) {
    return state.displayedCoachMark
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
