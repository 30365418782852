import http from '@/js/utils/http'
import API from '@/js/const/api'
import { deepCopy } from '@/js/utils/util'
import { getBeforeDate, getCurrentDate, getDateObject, getIsoDateStr } from '@/js/utils/date'

const state = {
  setting: {
    operationAreaList: null,
    detectionSetting: null,
  },
  snapshotUrl: null,
}

const mutations = {
  setOperationAnalysisSetting(state, setting) {
    state.setting = setting
  },
  setOperationAnalysisAreaSetting(state, operationAreaList) {
    state.setting.operationAreaList = operationAreaList
  },
  setOperationAnalysisDetectionSetting(state, detectionSetting) {
    state.setting.detectionSetting = detectionSetting
  },
  setSnapshotUrl(state, snapshotUrl) {
    state.snapshotUrl = snapshotUrl
  },
}

const getters = {
  operationAnalysisSetting(state) {
    return deepCopy(state.setting)
  },
  snapshotUrl() {
    return state.snapshotUrl
  },
}

const actions = {
  /**
   * 作業分析設定の取得
   * @param {String} cameraCode カメラコード
   */
  async getOperationAnalysisSetting({ commit }, cameraCode) {
    const api = API.GET_OPERATION_ANALYSIS_SETTING.replace('{cameraCode}', cameraCode)
    const res = await http.get(api)
    commit('setOperationAnalysisSetting', res.data.data)
  },
  /**
   * カメラ設定の更新
   * @param {String} cameraCode カメラコード
   * @param {Object} setting  設定
   */
  async updateOperationAnalysisDetectionSetting({ commit }, { cameraCode, setting }) {
    const apiPath = API.PUT_OPERATION_ANALYSIS_SETTING.replace('{cameraCode}', cameraCode)
    const res = await http.put(apiPath, setting)
    commit('setOperationAnalysisDetectionSetting', res.data.data)
  },
  /**
   * 作業エリアの更新
   * @param {String}  cameraCode カメラコード
   * @param {Array} operationAreaList 作業エリアリストを含むオブジェクト
   */
  async updateOperationAnalysisAreaSetting({ commit }, { cameraCode, operationAreaList }) {
    const api = API.PUT_OPERATION_ANALYSIS_AREA_SETTING.replace('{cameraCode}', cameraCode)
    const res = await http.put(api, { operationAreaList })
    commit('setOperationAnalysisAreaSetting', res.data.data)
  },
  /**
   * スナップショットを取得
   * @param {String}  cameraCode カメラコード
   */
  async getSnapshot({ commit }, cameraCode) {
    const api = API.SNAPSHOTS.replace('{cameraCode}', cameraCode)

    const date = getDateObject(getCurrentDate('YYYY-MM-DD HH:mm'))

    const params = {
      startDatetime: getIsoDateStr(getBeforeDate(date, 60, 'minute')),
      endDatetime: getIsoDateStr(date),
    }

    const res = await http.get(api, params)
    const imagesData = res.data.data.images
    const urlData = imagesData.find((d) => d.imageUrl !== '')
    const imgURL = urlData ? urlData.imageUrl : null

    commit('setSnapshotUrl', imgURL)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
