const state = {
  judgeSettingErrors: [],
}

const mutations = {
  setJudgeSettingErrors(state, errors) {
    state.judgeSettingErrors = errors
  },
}

const getters = {
  getJudgeSettingErrors(state) {
    return state.judgeSettingErrors
  },
  isValidJudgeSetting(state) {
    return state.judgeSettingErrors.flat().length === 0
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
