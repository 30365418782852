<template>
  <div class="search">
    <div class="search_btn" :class="{ isSetFilter: isSetFilter }">
      <icon-button @click="searchFieldToggle" :useBorder="false" icon="search" />
    </div>
    <transition name="slide">
      <div v-show="isSearchFieldOpen" class="search_toggle_box">
        <div class="search_input_wrap">
          <input
            v-model="keyword"
            type="search"
            class="search_input"
            @keyup.enter="search"
            data-test="search-input-keyword"
          />
          <icon-button
            :useBorder="false"
            icon="filter_list"
            :icon-color="filterIconColor"
            @click="toggleConditionDialog"
            data-test="search-icon-button-filter-list"
          />
        </div>
        <icon-button
          class="trigger_btn"
          :useBorder="false"
          bgColor="primary"
          icon="keyboard_arrow_right"
          icon-color="color-white"
          @click="search"
          data-test="search-icon-button-trigger"
        />
      </div>
    </transition>
    <condition-dialog
      v-model:toggleDisplayState="toggleDisplayState"
      v-model:selectedTags="selectedTags"
      v-model:selectedFunc="selectedFunc"
      v-model:selectedWatchStatus="selectedWatchStatus"
      :tags="tags"
      @search="search"
    />
  </div>
</template>
<script>
import IconButton from '@/components/molecules/IconButton'
import ConditionDialog from './ConditionDialog'
import { mapGetters } from 'vuex'
import { splitStringOnSpace, checkPlatform } from '@/js/utils/util'
import { formatQueryToArray } from '@/js/utils/filters'

export default {
  name: 'search',
  components: {
    IconButton,
    ConditionDialog,
  },
  data() {
    return {
      // 検索ボックスの開閉用フラグ
      isSearchFieldOpen: true,
      // ダイアログの開閉フラグ
      toggleDisplayState: false,
      // 検索キーワード
      keyword: '',
      // 検索タグ
      selectedTags: [],
      // 検索機能
      selectedFunc: [],
      selectedWatchStatus: '',
      detailQuery: null,
    }
  },
  created() {
    // 設定済みのキーワードがあれば設定
    this.keyword = this.routeQuery.filter.join(' ')
    this.resetConditions()

    if (checkPlatform() === 'sm') {
      this.isSearchFieldOpen = false
    }
  },
  computed: {
    ...mapGetters({
      tags: 'tag/tags',
    }),
    //  URLから現在のqueryを呼ぶ
    routeQuery() {
      const queryData = this.detailQuery ? this.detailQuery : this.$route.query
      const query = {
        filter: formatQueryToArray(queryData.filter) || [],
        tags: formatQueryToArray(queryData.tags) || [],
        func: formatQueryToArray(queryData.func) || [],
      }
      if (queryData.watch_status) {
        query.watch_status = queryData.watch_status
      }
      return query
    },
    /**
     * 検索フィルタ設定済みの判定用
     * @return {Boolean} キーワード、タグ、機能の中で一つでも設定項目があればtrueを返却
     */
    isSetFilter() {
      // detailQueryがある場合は保存されている情報を利用
      const query = this.detailQuery ? this.detailQuery : this.routeQuery

      return Object.keys(query)
        .map((key) => query[key])
        .some((d) => d.length > 0)
    },
    /**
     * フィルタアイコンの色
     * @return {String} アイコンの色
     */
    filterIconColor() {
      return this.isSetFilter ? 'color-secondary' : null
    },
  },
  methods: {
    searchFieldToggle() {
      this.isSearchFieldOpen = !this.isSearchFieldOpen
    },
    toggleConditionDialog() {
      this.resetConditions()
      this.toggleDisplayState = !this.toggleDisplayState
    },
    // タグの検索条件が変更されたら呼ばれる
    changeSearchTags(v) {
      this.selectedTags = v
    },
    /**
     * 検索
     */
    search() {
      const query = {
        filter: [],
        tags: [],
        func: [],
        watch_status: '',
      }

      // キーワードのパラメータを設定
      if (this.keyword !== '' && this.keyword !== null) {
        query.filter = splitStringOnSpace(this.keyword)
      }

      // タグのパラメータを設定
      query.tags = this.selectedTags

      // カメラ機能のパラメータを設定
      query.func = this.selectedFunc

      query.watch_status = this.selectedWatchStatus

      if (query.watch_status === '') {
        delete query.watch_status
      }

      // viewがある場合URLに維持する
      if (this.$route.query.view) {
        query.view = this.$route.query.view
      }
      if (this.$route.query.division) {
        query.division = this.$route.query.division
      }

      this.$router
        .push({
          name: 'list',
          params: { page: 1 },
          query: query,
        })
        .catch(() => {})
    },
    resetConditions() {
      this.keyword = this.routeQuery.filter.join(' ') || ''
      this.selectedTags = this.routeQuery.tags || []
      this.selectedFunc = this.routeQuery.func || []
      this.selectedWatchStatus = this.routeQuery.watch_status
    },
  },
  watch: {
    $route(to, from) {
      // カメラリストから詳細ページに移動した場合queryの情報を保存するために
      if (to.name === 'detail' && from.name === 'list') {
        this.detailQuery = from.query
        // detailページ以外は保存しない
      } else if (to.name !== 'detail') {
        this.detailQuery = null
      }
    },
    routeQuery() {
      // 検索条件が更新された場合モーダルの設定情報も更新する
      // ここで更新しないと、サイドナビを押した場合など検索条件が更新された場合にselectedTags、selectedFuncが更新されない
      this.resetConditions()
    },
  },
}
</script>

<style scoped lang="scss">
.search {
  display: flex;
  background: $color-white;

  .search_toggle_box {
    overflow: hidden;
    display: flex;

    > div {
    }
  }

  .search_input_wrap {
    display: flex;
    background: $color-dark5;
  }

  .search_input {
    display: block;
    height: $input-ui-size-1;
    appearance: none;
    border: none;
    box-shadow: none;
    padding: 0 $size-s;
    font-size: $size-m;
    background: none;
    width: 200px;
    @include sm {
      width: 150px;
    }
  }

  .trigger_btn {
    margin-left: $size-s;
  }
}

.slide-enter-active {
  animation: animation $duration-normal;
}

.slide-leave-active {
  animation: animation $duration-normal reverse;
}

.search_btn {
  position: relative;

  &.isSetFilter {
    @include badge;
  }
}

@keyframes animation {
  0% {
    width: 0;
  }
  100% {
    width: 300px;
  }
}
</style>
