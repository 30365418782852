const defaultValue = {
  liveState: false,
}

const state = {
  liveState: defaultValue.liveState,
}

const mutations = {
  setLiveState(state, liveState) {
    state.liveState = liveState
  },
  // 設定を初期値にクリア
  resetSetting(state) {
    state.liveState = defaultValue.liveState
  },
}

const getters = {
  liveState(state) {
    return state.liveState
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
