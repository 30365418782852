<template>
  <div class="notification_tooltip">
    <transition v-for="(notification, $index) in unreadNotifications" :key="$index">
      <span class="tooltip" :style="toolTipStyle($index)" data-test="tooltip">
        <span @click="notificationPage(notification)">{{ message[notification.event] }}</span>
        <span class="tooltip_close_btn" @click="closeNotification(notification)">
          <icon type="close" size="15" color="color-dark3"></icon>
        </span>
      </span>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { deepCopy } from '@/js/utils/util'
import APP from '@/js/const/app'
import Icon from '@/components/atoms/Icon'

// PushSubscriptionの更新用
const UPDATE_SUBSCRIPTION_INTERVAL = APP.PUSH_SUBSCRIPTION_EXPIRES_UPDATE_INTERVAL
// 既読バッジの更新用
const POLLING_INTERVAL = 60 * 1000

export default {
  name: 'NotificationToolTip',
  components: {
    Icon,
  },
  data() {
    return {
      message: APP.NOTIFICATION_MESSAGES,
      getNotificationsReadStateTimer: null,
      saveSubscriptionTimer: null,
    }
  },
  async created() {
    // 既読バッジ表示用
    await this.getNotificationsReadState()

    if (this.isWebPushSubscribed) {
      // webプッシュ利用可
      this.initWebPush()
      // 画面表示時にPushSubscriptionの保存(有効期限を更新する為)
      await this.saveSubscription()
    } else {
      // webプッシュ利用不可
      this.initNotificationReadState()
    }
  },
  beforeUnmount() {
    this.removePushMessageEvent()
  },
  computed: {
    ...mapGetters({
      readState: 'notification/readState',
      tooltipState: 'notification/tooltipState',
      unreadNotifications: 'notification/unreadNotifications',
      isWebPushSubscribed: 'webPush/isSubscribed',
      userInfo: 'auth/userInfo',
    }),
  },
  methods: {
    ...mapActions({
      getNotificationsReadState: 'notification/getNotificationsReadState',
      saveSubscription: 'webPush/saveSubscription',
      deleteSubscription: 'webPush/deleteSubscription',
    }),
    ...mapMutations({
      setTooltipState: 'notification/setTooltipState',
    }),
    /**
     *  toolチップの表示と、既読バッジ表示機能の初期化
     */
    initNotificationReadState() {
      // 通知情報を取得
      this.getNotificationsReadStateTimer = setInterval(async () => {
        // 既読バッジ表示とツールチップ表示用に通知既読情報を取得
        await this.getNotificationsReadState()
        // storeで永続化しているデータをセット
        this.setTooltipState(this.getDisplayToolTipData())
      }, POLLING_INTERVAL)
    },
    /**
     * webプッシュ機能の初期化
     */
    initWebPush() {
      // PushSubscriptionの保存(有効期限を更新する為)
      this.saveSubscriptionTimer = setInterval(this.saveSubscription, UPDATE_SUBSCRIPTION_INTERVAL)
      // serviceWorkerからのメッセージを受け取るようにイベントを設定
      navigator.serviceWorker.addEventListener('message', this.pushMessage)
    },
    /**
     * webプッシュを表示
     */
    pushMessage(event) {
      const message = JSON.parse(event.data)
      const tooltipState = deepCopy(this.tooltipState)
      // 通知のuidがログインユーザーのuidと一致しない（別のユーザー）の場合は通知をしない
      if (this.userInfo.uid !== message.to) {
        return
      }

      // 既読バッジ表示用に通知既読情報を取得
      this.getNotificationsReadState()

      // 特定のイベントのみ表示する
      // TODO 全イベント表示したほうがよい？
      if (Object.keys(this.tooltipState).includes(message.body.event)) {
        tooltipState[message.body.event] = {
          date: message.dateTime,
          state: true,
        }
        this.setTooltipState(tooltipState)
      }
    },
    /**
     * notification tooltipを閉じる
     * @param {Object} notification 通知データ
     */
    closeNotification(notification) {
      const state = deepCopy(this.tooltipState)
      // ここのstateはunreadという意味(つまりツールチップ上は既読とする)
      state[notification.event].state = false
      state[notification.event].date = this.isWebPushSubscribed
        ? notification.datetime
        : notification.latestNotification.dateTime

      this.setTooltipState(state)
    },
    /**
     * notification ページに入る
     * @param {Object} notification 通知データ
     */
    notificationPage(notification) {
      if (this.$route.path !== '/notification') {
        this.$router.push({ name: 'notification', query: { actTab: notification.event } })
      }
      this.closeNotification(notification)
    },
    /**
     * ツールチップの状態を既読情報から設定して返却する
     */
    getDisplayToolTipData() {
      const tooltipData = deepCopy(this.tooltipState)
      // ツールチップを表示するイベントの既読情報を取得(現状だとDownload、EnterAlert)
      const targetEvents = this.readState.filter((d) => Object.keys(tooltipData).includes(d.event))
      // ツールチップに表示する既読情報をstoreにセットする
      targetEvents.forEach((d) => {
        // 一定間隔で実行されるので、既読情報がstoreに保持している情報と比較する
        // storeに保存している日付と、既読情報から取得している日付が一致しない場合(新しい通知）のみツールチップを表示する
        if (d.latestNotification && d.latestNotification.dateTime !== tooltipData[d.event].date) {
          // stateはunreadという意味なので、trueにすることでツールチップを表示する
          tooltipData[d.event] = {
            date: d.latestNotification.dateTime,
            state: true,
          }
        }
      })

      return tooltipData
    },
    /**
     * ツールチップの表示位置
     * @param {Number} index 表示位置
     */
    toolTipStyle(index) {
      return {
        top: `${45 * (index + 1)}px`,
      }
    },
    /**
     * ツールチップ表示用のイベントと、タイマーを削除
     */
    removePushMessageEvent() {
      // タイマーが重複設定されないようにクリアする
      if (this.getNotificationsReadStateTimer) {
        clearInterval(this.getNotificationsReadStateTimer)
      }

      if (this.saveSubscriptionTimer) {
        clearInterval(this.saveSubscriptionTimer)
      }
      // イベントリスナーが重複登録されないように削除
      navigator.serviceWorker.removeEventListener('message', this.pushMessage)
    },
  },
  watch: {
    // 設定画面からWebプッシュの利用switchを操作した場合の対応
    isWebPushSubscribed(v) {
      this.removePushMessageEvent()
      if (v) {
        this.initWebPush()
      } else {
        this.initNotificationReadState()
      }
    },
  },
}
</script>

<style scoped lang="scss">
$positionBottom: 45px;
.notification_tooltip {
  text-align: center;
  > *:not(:first-child) {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
}
.tooltip {
  width: 300px;
  position: absolute;
  background: #f0f6fb;
  padding: $size-s $size-m;
  border-radius: $radius-s;
  z-index: 20;
  color: $color-primary;
  top: $positionBottom;
  right: 0;
  border: $color-primary solid 1px;
  @include fs($font-size-5);
  @include shadow_dialog();
  > span {
    cursor: pointer;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -20px;
    right: 10px;
    @include sankaku(10px, $color-primary, up);
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -19px;
    right: 10px;
    @include sankaku(10px, #f0f6fb, up);
  }
}
.tooltip_close_btn {
  position: absolute;
  bottom: 65%;
  right: 1.5em;
  width: 5px;
  height: 5px;
  background: #f0f6fb;
  color: #d5d5d5;
  z-index: 21;
  cursor: pointer;
}
</style>
