<template>
  <div v-if="!isZeroItem">
    <div v-show="displayType === 'list'" data-test="camera-list">
      <camera-list-row
        v-for="(camera, index) in cameraList"
        :cameraData="camera"
        :key="index"
        :displayCheckBox="displayCheckBox"
        v-model:checked="checkedStatuses[camera.camera_code]"
        :listType="listType"
        :downCameraCondition="downCameraCondition"
        @update:checked="update"
        @setTag="$emit('setTag', $event)"
        @openMap="openMap"
        @showCameraInfoDialog="showCameraInfoDialog"
      >
      </camera-list-row>
    </div>

    <div v-show="displayType === 'tile'" class="camera_list_tile_wrap" data-test="camera-list-tile">
      <white-box>
        <camera-list-tile v-for="(camera, index) in cameraList" :cameraData="camera" :key="index" />
      </white-box>
    </div>

    <div v-if="displayType === 'map'" data-test="camera-map">
      <white-box class="map_white_box">
        <maps
          :cameraList="cameraList"
          :totalCameras="totalCameras"
          :pageSize="pageSize"
          :currentPage="currentPage"
          :selectCamera="selectCamera"
          @changePage="$emit('changePage', $event)"
        ></maps>
      </white-box>
    </div>
    <pagination
      v-show="displayType !== 'map'"
      :total="totalCameras"
      :pageSize="pageSize"
      :currentPage="currentPage"
      @currentChange="$emit('changePage', $event)"
    ></pagination>
    <camera-detail-dialog v-model="showCameraInfoDialogFlag" :cameraData="cameraInfoData" />
  </div>
  <!-- ゼロ表示 -->
  <zero-item v-else @clear="$emit('clearFilter')" />
</template>

<script>
import CameraListRow from '@/components/molecules/CameraListRow'
import CameraListTile from '@/components/molecules/CameraListTile'
import Maps from '@/components/organisms/Map'
import WhiteBox from '@/components/atoms/WhiteBox'
import Pagination from '@/components/molecules/Pagination'
import ZeroItem from '@/components/pages/Cameras/components/ZeroItem'
import CameraDetailDialog from '@/components/organisms/CameraDetailDialog'
import http from '@/js/utils/http'
import API from '@/js/const/api'

export default {
  name: 'CameraList',
  components: {
    CameraDetailDialog,
    ZeroItem,
    CameraListRow,
    CameraListTile,
    Maps,
    WhiteBox,
    Pagination,
  },
  mounted() {
    // チェックボックスの初期値を設定
    this.setCheckedStatuses(false)
  },
  props: {
    cameraList: {
      type: Array,
      default: () => [],
    },
    displayType: {
      type: String,
      default: 'list',
    },
    displayCheckBox: {
      type: Boolean,
      default: false,
    },
    useAllCheck: {
      type: Boolean,
      default: false,
    },
    totalCameras: {
      type: Number,
      default: 50,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    noSelectCamera: {
      type: Boolean,
      default: true,
    },
    listType: {
      type: String,
      default: null,
    },
    checkedCameras: {
      type: Array,
      default: () => [],
    },
    downCameraCondition: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkedStatuses: {},
      detailListShow: false,
      selectCamera: null,
      selectedMotionDetectAreaSettingCamera: {},
      showCameraInfoDialogFlag: false,
      cameraInfoData: {},
    }
  },
  computed: {
    isZeroItem() {
      return this.totalCameras === 0
    },
  },
  methods: {
    setCheckedStatuses(v) {
      this.cameraList.forEach((d) => {
        this.checkedStatuses[d.camera_code] = v
      })
      this.$forceUpdate()
    },
    update() {
      // TODO オブジェクトのデータが更新されないので再描画。もっと良い方法を考える
      this.$forceUpdate()

      // 選択済みのカメラコードだけ抽出
      const checkedCameras = this.cameraList.filter((d) => {
        return this.checkedStatuses[d.camera_code]
      })

      this.$emit('cameraChecked', checkedCameras)
    },
    openMap(cameraData) {
      this.selectCamera = cameraData
      this.$emit('openMap')
    },
    /**
     * カメラ詳細情報ダイアログを表示
     */
    showCameraInfoDialog(code) {
      const detailApi = API.CAMERA_DETAIL.replace('{camera_code}', code)

      // 映像詳細情報を取得
      http.get(detailApi).then((res) => {
        this.cameraInfoData = res.data.data
        this.showCameraInfoDialogFlag = true
      })
    },
  },
  watch: {
    useAllCheck(v) {
      this.setCheckedStatuses(v)
    },
    noSelectCamera(b) {
      if (b) {
        this.selectCamera = null
      }
    },
    checkedCameras() {
      this.cameraList.forEach((d) => {
        this.checkedStatuses[d.camera_code] = this.checkedCameras.includes(d)
      })
      this.$forceUpdate()
    },
  },
}
</script>
<style scoped lang="scss">
.camera_list_tile_wrap {
  margin-bottom: $size-xl;
}
.camera_list_tile_wrap > .white_box {
  display: flex;
  flex-flow: wrap;

  > * {
    @include more_md {
      @include tile_layout(5);
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
    @include sm {
      @include tile_layout(3);
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
.map_white_box {
  position: relative;
}
</style>
