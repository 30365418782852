import PLATFORM_CONST from '@/js/const/platform'
import { gsap } from 'gsap'
import moment from 'moment'
import { ElMessage } from 'element-plus'
import i18n from '@/plugins/i18n'
import VueI18n from 'vue-i18n'
import { CameraDetail, Camera } from '@/@types/typescript-axios/api'

declare global {
  interface DocumentWithFullscreen extends Document {
    mozCancelFullScreen?: () => void
    msExitFullscreen?: () => void
    cancelFullScreen?: () => void
    webkitCancelFullScreen?: () => void
    mozFullScreenElement?: Element
    msFullscreenElement?: Element
    webkitFullscreenElement?: Element
    webkitFullscreenEnabled?: boolean
    mozFullScreenEnabled?: boolean
    msFullscreenEnabled?: boolean
  }
  interface ElementWithFullscreen extends Element {
    msRequestFullscreen?: () => void
    mozRequestFullScreen?: () => void
    webkitRequestFullscreen?: () => void
  }
}

/**
 * PC、タブレット、スマホ判定用の関数です(画面大きさによる)
 * @return スマホサイズの場合 : sp, タブレットサイズの場合: md, PCサイズの場合:lgを返却
 */
export function checkPlatform(): string {
  const ww = window.innerWidth
  if (ww <= PLATFORM_CONST.SP_BREAK_POINT) {
    return 'sm'
  } else if (ww < PLATFORM_CONST.PC_BREAK_POINT) {
    return 'md'
  } else {
    return 'lg'
  }
}
/**
 * PC、タブレット、スマホ判定用の関数です(実際の機器判定)
 * @return スマホの場合 : sp, タブレットの場合: tab, PCの場合:pcを返却
 */
export function getEquipmentType(): string {
  const ua = navigator.userAgent
  if (
    ua.indexOf('iPhone') > 0 ||
    ua.indexOf('iPod') > 0 ||
    (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)
  ) {
    return 'sp'
  } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
    return 'tab'
  } else {
    return 'pc'
  }
}

/**
 * 全角、半角スベースをスプリット
 * @param str 文字列
 * @return 分割した文字列
 */
export function splitWhitespace(str: string): string[] {
  return str.split(/[\u{20}\u{3000}]/u)
}

/**
 * deepcopy
 * @param data コピー元 (Dateオブジェクトなどでは利用できない)
 * @return コピーデータ
 */
export function deepCopy<T>(data: T): T {
  return JSON.parse(JSON.stringify(data))
}

/**
 * 画面上部までスクロール
 * @param duration 状態が変化する期間(0.5 = 0.5秒)
 */
export function scrollToTop(duration = 0.5): void {
  const tgt = document.documentElement || document.body
  gsap.fromTo(
    tgt,
    { scrollTop: tgt.scrollTop },
    {
      scrollTop: 0,
      duration,
    }
  )
  tgt.scrollTop = 0
}

/**
 * 動画ダウンロード
 * @param data 映像のバイナリデータ
 * @param name ファイル名
 */
export function downloadVideo(data: Blob, name = 'camera'): void {
  const blob = new Blob([data], { type: 'video/mp4' })
  const link = document.createElement('a')
  const fileName = `${name}.mp4`

  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * CSVダウンロード
 * @param data 映像のバイナリデータ
 * @param name ファイル名
 */
export function downloadCsv(data: Blob, name = 'camera'): void {
  // エクセル用にバイトオーダーマーク(UTF-8)をつける
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const blob = new Blob([bom, data], { type: 'text/csv' })
  const link = document.createElement('a')
  const fileName = `${name}.csv`

  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * JSONダウンロード
 * @param jsonData ダウンロードするデータ
 * @param name ファイル名
 */
export function downloadJson(jsonData: {}, name = 'camera'): void {
  const blob = new Blob([JSON.stringify(jsonData)], {
    type: 'application/json',
  })

  const link = document.createElement('a')
  const fileName = `${name}.json`

  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * 動画のファイル名を作成
 * @param cameraCode カメラコード
 * @param date 日付の文字列やDate
 * @param format 日付文字列のフォーマット形式
 * @return ダウンロードするファイル名の文字列
 */
export function getDownloadFileName(
  cameraCode: string,
  date: Date | string,
  format = 'YYYYMMDDHHmmss'
): string {
  return `${cameraCode}_${moment(date).format(format)}`
}

/**
 * jpeg file DownLoad
 * @param src
 * @param name ファイル名
 */

export function imgDownload(src: string, name = 'imgFile'): void {
  const link = document.createElement('a')
  link.href = src
  link.download = `${name}.jpg`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * file DownLoad
 * @param url download url
 */

export function downloadURLFile(url: string): void {
  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * カメラ詳細情報からevent iconを取得
 * @param data カメラの詳細情報
 * @param environmentMode 動作モード
 * @return 表示するイベントアイコン名の配列
 */
export function getIcons(
  data: CameraDetail | Camera,
  environmentMode = ''
): { icon: string; text: VueI18n.TranslateResult }[] {
  const icons = []

  //  迷惑駐車は未サポートなので表示しない
  // if (data.use_parking_detect) {
  //   icons.push({ icon: 'illegalParking', text: i18n.global.t('util_js.illegal_parking') })
  // }
  if (data.use_motion) {
    icons.push({ icon: 'motion', text: i18n.global.t('util_js.motion_detection') })
  }

  if (data.use_camera_motion) {
    icons.push({ icon: 'cameraMotion', text: i18n.global.t('util_js.camera_motion_detection') })
  }

  if (data.use_heatmap) {
    icons.push({ icon: 'heatmap', text: i18n.global.t('util_js.heatmap') })
  }

  if (!data.use_nolive) {
    icons.push({ icon: 'liveCamera', text: i18n.global.t('util_js.live_video') })
  }
  if (data.use_audio) {
    icons.push({ icon: 'audio', text: i18n.global.t('util_js.audio') })
  }

  if (data.use_people_counter) {
    icons.push({ icon: 'peopleCounter', text: i18n.global.t('util_js.people_counter') })
  }

  if (data.use_ptz) {
    icons.push({ icon: 'ptz', text: i18n.global.t('util_js.ptz') })
  }

  if (data.use_time_lapse) {
    icons.push({ icon: 'timeLapse', text: i18n.global.t('util_js.time_lapse') })
  }
  // TLT専用
  if (environmentMode.startsWith('TLT')) {
    if (data.use_enter_alert) {
      icons.push({ icon: 'enterAlert', text: i18n.global.t('util_js.enter_alert') })
    }

    if (data.use_person_tracking) {
      icons.push({ icon: 'personTracking', text: i18n.global.t('util_js.person_tracking') })
    }

    if (data.use_operation_analysis) {
      icons.push({ icon: 'operationAnalysis', text: i18n.global.t('util_js.operation_analysis') })
    }

    if (data.use_dewarp) {
      icons.push({ icon: 'dewarp', text: i18n.global.t('util_js.dewarp') })
    }
  }

  return icons
}

/**
 * snake caseからCamelCaseに変換
 * @param str
 * @return CamelCaseに変換
 */
export function toCamelCase(str: string): string {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

/**
 * 連番を作成
 * @param n 作成する連番の長さ
 * @return 指定の長さの配列([1,2...])
 */
export function range(n: number): number[] {
  return [...Array(n).keys()]
}

/**
 * スペースで文字列をsplitして配列を作る
 * @param str 文字列
 * @return スペースで分割した文字列
 */
export function splitStringOnSpace(str: string): string[] {
  const s = str.split(/[\u{20}\u{3000}]|,/u)
  return s.filter(Boolean)
}

/**
 * クリップボードにコピー
 * @param val コピーする文字列、数値
 * @param copyDom コピーする element
 */
export function copyClipboard(val: string | number, copyDom: HTMLElement): void {
  if (navigator.clipboard !== undefined) {
    // Chrome and firefox
    navigator.clipboard.writeText(val as string).then(
      () => {
        ElMessage({
          message: i18n.global.t('util_js.copied_to_clipboard') as string,
          type: 'success',
        })
      },
      () => {
        ElMessage({
          message: i18n.global.t('util_js.copy_failed') as string,
          type: 'error',
        })
      }
    )
  } else if (useBrowserName() === 'safari') {
    const selection = window.getSelection()
    if (selection !== null) {
      // 選択されているところがあったら削除
      selection.removeAllRanges()
      const shareUrl = copyDom
      const range = document.createRange()
      range.selectNode(shareUrl)
      selection.addRange(range)
      document.execCommand('copy')
      selection.removeAllRanges()
      ElMessage({
        message: i18n.global.t('util_js.copied_to_clipboard') as string,
        type: 'success',
      })
    }
  } else {
    ElMessage({
      message: i18n.global.t('util_js.copy_failed') as string,
      type: 'error',
    })
  }
}

/**
 * フルスクリーン表示
 * @param elm フルスクリーン表示するDOM
 * @param func コールバック関数
 */
export function fullScreen(elm: Element, func: EventListenerOrEventListenerObject): void {
  const element = elm as ElementWithFullscreen
  document.addEventListener('webkitfullscreenchange', func)
  document.addEventListener('mozfullscreenchange', func)
  document.addEventListener('MSFullscreenChange', func)
  document.addEventListener('fullscreenchange', func)

  if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen()
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen()
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen()
  } else if (element.requestFullscreen) {
    element.requestFullscreen()
  }
}

/**
 * フルスクリーン解除
 * @param func 解除するコールバック関数
 */
export function exitFullScreen(func: EventListenerOrEventListenerObject): void {
  const doc = document as DocumentWithFullscreen
  doc.removeEventListener('webkitfullscreenchange', func)
  doc.removeEventListener('mozfullscreenchange', func)
  doc.removeEventListener('MSFullscreenChange', func)
  doc.removeEventListener('fullscreenchange', func)

  if (doc.webkitCancelFullScreen) {
    doc.webkitCancelFullScreen()
  } else if (doc.mozCancelFullScreen) {
    doc.mozCancelFullScreen()
  } else if (doc.msExitFullscreen) {
    doc.msExitFullscreen()
  } else if (doc.cancelFullScreen) {
    doc.cancelFullScreen()
  } else if (doc.exitFullscreen) {
    doc.exitFullscreen()
  }
}

/**
 * フルスクリーン表示中のエレメントを取得する関数
 * @return フルスクリーン中のエレメント
 */
export function documentGetFullscreenElement(): Element | null {
  const doc = document as DocumentWithFullscreen
  return (
    doc.fullscreenElement ||
    doc.webkitFullscreenElement ||
    doc.mozFullScreenElement ||
    doc.msFullscreenElement ||
    null
  )
}

/**
 * フルスクリーンAPIの有無判定用
 * @return APIが有ならtrue
 */
export function fullscreenUseCheck(): boolean {
  const doc = document as DocumentWithFullscreen
  return (
    doc.fullscreenEnabled /* Standard syntax */ ||
    doc.webkitFullscreenEnabled /* Chrome, Safari and Opera syntax */ ||
    doc.mozFullScreenEnabled /* Firefox syntax */ ||
    doc.msFullscreenEnabled /* IE/Edge syntax */ ||
    false
  )
}

/**
 * 推奨ブラウザ判定
 * @return 推奨ブラウザの場合はtrue
 */
export function isRecommendedBrowser(): boolean {
  const userAgent = window.navigator.userAgent.toLowerCase()
  if (userAgent.indexOf('edge') !== -1) {
    return true
  } else if (userAgent.indexOf('chrome') !== -1) {
    return true
  } else if (userAgent.indexOf('safari') !== -1) {
    return true
  }
  return false
}

/**
 * ブラウザ判定
 * @return ブラウザの名前
 */
export function useBrowserName(): string | undefined {
  const userAgent = window.navigator.userAgent.toLowerCase()
  // Microsoft Edge (EdgeHTML 版)
  if (userAgent.indexOf('edge') !== -1) {
    return 'edge'
    // Microsoft Edge (Chromium 版)
  } else if (userAgent.indexOf('edg') !== -1) {
    return 'edg'
  } else if (userAgent.indexOf('firefox') !== -1) {
    return 'firefox'
  } else if (userAgent.indexOf('chrome') !== -1) {
    return 'chrome'
    // safari chromeとedgeにもsafariの文字があるので除くために追加
  } else if (
    userAgent.indexOf('safari') !== -1 &&
    userAgent.indexOf('chrome') === -1 &&
    userAgent.indexOf('edge') === -1
  ) {
    return 'safari'
  } else if (userAgent.indexOf('trident/') !== -1 || userAgent.indexOf('MSIE') !== -1) {
    return 'IE'
  }
}

/**
 * OS名を取得
 * @param userAgent User-Agent 文字列
 * @return OS名
 */
export function getOsName(userAgent: string): string | undefined {
  const userAgentLower = userAgent.toLowerCase()

  if (userAgentLower.indexOf('windows nt') !== -1) {
    return 'Windows'
  } else if (userAgentLower.indexOf('android') !== -1) {
    return 'Android'
  } else if (userAgentLower.indexOf('iphone') !== -1 || userAgentLower.indexOf('ipad') !== -1) {
    return 'iOS'
  } else if (userAgentLower.indexOf('mac os x') !== -1) {
    return 'mac'
  }
}

/**
 * イベント間引き処理
 * @param fn 実行する関数
 * @param interval 間引く間隔
 */
export function debounce(fn: () => void, interval = 500): () => void {
  let timer: number | null = null
  return () => {
    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => {
      fn()
      timer = null
    }, interval)
  }
}

/**
 * 画像の縦横比を取得
 * @param w 画像の幅
 * @param h 画像の高さ
 * @return 縦横比
 */
export function getAspectRatio(w: number, h: number): string {
  const g = gcd(w, h)
  return `${w / g}:${h / g}`
}

/**
 * 画像の縦横比を取得の為に公約数取得
 */
function gcd(x: number, y: number): number {
  if (y === 0) return x
  return gcd(y, x % y)
}
