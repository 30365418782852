<template>
  <dialog-base
    v-model="internalValue"
    @approve="updatePassword"
    @close="passwordInit"
    :ok-btn-disabled="okBtnDisabled"
    :loading="loading"
  >
    <template v-slot:title>
      <h1 class="title">{{ $t('password_dialog.change_password') }}</h1>
    </template>
    <template v-slot:body>
      <div>
        <p class="pw_dialog_title">{{ $t('password_dialog.please_enter') }}</p>
        <div class="password_input_wrap">
          <form @keyup.enter="updatePassword">
            <password-input-filed
              v-model="password1"
              type="password"
              rule="required|min:6"
              name="password_input"
              :name-as="$t('validation.password')"
              :placeholder="$t('password_dialog.new_password')"
              klass="password_dialog_input"
              ref="password_input"
              :setFocusState="setFocusState"
            />
            <password-input-filed
              v-model="password2"
              type="password"
              rule="required|min:6"
              name="password_check"
              :name-as="$t('validation.password')"
              :placeholder="$t('password_dialog.confirm_password')"
              klass="password_dialog_input"
            />
            <span v-if="notMatchPasswords" class="error_default">
              {{ $t('password_dialog.not_match') }}
            </span>
          </form>
        </div>
      </div>
    </template>
  </dialog-base>
</template>

<script>
import DialogBase from '@/components/molecules/DialogBase'
import PasswordInputFiled from '@/components/molecules/PasswordInputField'
import http from '@/js/utils/http.js'
import API from '@/js/const/api'
import i18n from '@/plugins/i18n'

export default {
  name: 'PasswordDialog',
  components: {
    DialogBase,
    PasswordInputFiled,
  },
  data() {
    return {
      password1: '',
      password2: '',
      loading: false,
      setFocusState: false,
    }
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'close'],
  methods: {
    updatePassword() {
      if (
        this.password1 !== this.password2 ||
        this.password1.length < 6 ||
        this.password2.length < 6
      ) {
        return
      }
      this.loading = true
      http
        .put(API.UPDATE_PASSWORD, { password: this.password1 })
        .then(() => {
          this.loading = false
          this.$message({
            message: i18n.global.t('password_dialog.successful'),
            type: 'success',
          })
          this.passwordInit()
          this.$emit('close')
        })
        .catch(() => {
          this.loading = false
          this.$message({
            message: i18n.global.t('password_dialog.failed'),
            type: 'error',
          })
        })
    },
    passwordInit() {
      this.password1 = ''
      this.password2 = ''
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.modelValue
      },
      set(newVal) {
        if (this.modelValue !== newVal) {
          this.$emit('update:modelValue', newVal)
        }
      },
    },
    okBtnDisabled() {
      if (this.password1 !== this.password2) {
        return true
      }
      if (this.password1.length < 6 || this.password2.length < 6) {
        return true
      }
      return false
    },
    notMatchPasswords() {
      if (this.password1.length < 6 || this.password2.length < 6) {
        return false
      }
      return this.password1 !== this.password2
    },
  },
  watch: {
    modelValue() {
      if (this.modelValue) {
        this.$nextTick(() => {
          this.setFocusState = true
        })
      } else {
        this.setFocusState = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.pw_dialog_title {
  @include fs($font-size-5);
  padding-bottom: $size-m;
}
.password_input_wrap {
  padding-bottom: $size-s;
  .password_input_field {
    width: 100%;
    position: relative;
    display: inline-block;
    margin-bottom: $size-m;
  }
}
.error_default {
  @include fs($font-size-6);
  display: block;
  margin-top: -$size-s;
  color: $color-secondary;
}
</style>
