export default {
  LOGIN: 'api/auth/login',
  LOGOUT: 'api/auth/logout',
  CHECK_LOGIN: 'api/auth/checklogin',
  TOKEN_REFRESH: 'api/auth/refresh',
  LOGIN_USER: 'api/auth/me',
  UPDATE_PASSWORD: 'api/auth/password',
  APP_KEY: 'api/auth/appkey',
  CHECK_APP_KEY: 'api/auth/checkappkey',
  CAMERAS: 'api/cameras',
  CAMERA_ARCHIVES: 'api/cameras/{camera_code}/archives',
  CAMERA_LIGHTING: 'api/cameras/{camera_code}/lighting',
  CAMERA_DETAIL: 'api/cameras/{camera_code}',
  CAMERA_TAG_ASSIGN: 'api/cameratagsassign',
  CAMERA_CONTROL: 'api/cameras/{camera_code}/control',
  START_LIVE: 'api/cameras/{camera_code}/startlive',
  CAMERA_SHOTS: 'api/cameras/{camera_code}/shots',
  CAMERA_SPOTS: 'api/cameras/{camera_code}/spots',
  LIVE_IMG: 'api/cameras/{camera_code}/image',
  STREAMING: 'api/cameras/{cameraCode}/start-streaming',
  LOCATIONS: 'api/locations',
  NOTIFICATIONS: 'api/notifications',
  UPDATE_NOTIFICATIONS: 'api/notifications/{notification_id}',
  CAMERA_TAGS: 'api/cameratags',
  SNAPSHOTS: 'api/cameras/{cameraCode}/snapshots',
  UPDATE_CAMERA_TAGS: 'api/cameratags/{tag_code}',
  ARCHIVE_DOWNLOAD: 'api/cameras/{camera_code}/archive_download',
  CAMERAS_LOCATION_RANGE: 'api/camerasbylocationrange',
  CAMERAS_LOCATION_CITY: 'api/camerasbylocationcity',
  CAMERAS_LOCATION_AREA: 'api/camerasbylocationarea',
  CAMERAS_LOCATION_CODE: 'api/camerasbylocationcode/{location_code}',
  WEATHER_INFO: 'api/weather/{date}/{time}/{type}',
  HEAT_MAP: 'api/heatmap',
  HEAT_MAP_REQUEST: 'api/heatmap_request',
  ARCHIVE_REQUEST: 'api/archive_request',
  PASSWORD_REQUEST_RESET: 'api/password/reset_request',
  PASSWORD_RESET: 'api/password/reset',
  CHILD_USERS: 'api/childusers',
  CHILD_USER_UPDATE: 'api/childusers/{userId}',
  CHILD_USER_DELETE_APPKEY: 'api/childusers/{userId}/appkey',
  CAMERA_CONFIG_MOTION: 'api/cameras/{cameraCode}/config/motion',
  CAMERA_RELATED: 'api/cameras/{camera_code}/related',
  NOTIFICATIONS_USER: 'api/notifications/user/{event}',
  NOTIFICATIONS_CAMERA: 'api/notifications/camera/{cameraCode}',
  NOTIFICATIONS_READ: 'api/notifications/read',
  NOTIFICATIONS_READ_UPDATE: 'api/notifications/read/{event}',
  PECO_CAMERA_SUMMARY: 'api/peco/camera/{camera_code}/summary/latest',
  PECO_LOCATION_SUMMARY: 'api/peco/location/{location_code}/summary/latest',
  PECO_CAMERA_ONEDAY: 'api/peco/camera/{camera_code}/summary/oneday',
  PECO_CAMERA_PERDAY: 'api/peco/camera/{camera_code}/summary/perday',
  PECO_CAMERA_PERHOUR: 'api/peco/camera/{camera_code}/summary/perhour',
  PECO_CAMERA_SETTING: 'api/peco/camera/{camera_code}/setting',
  TIME_LAPSE_LIST: 'api/cameras/{cameraCode}/time-lapse',
  TIME_LAPSE_ARCHIVE_REQUEST: 'api/time-lapse_archive_request',
  SETTING_ENV: 'api/settings/env',
  ARCHIVE_GET: 'api/archive_get',
  HEAT_MAP_GET: 'api/heatmap_get',
  SEND_FEEDBACK: 'api/feedback',
  SET_AREA_LINK: 'api/enter-alert/camera/{camera_code}/setting/area-link',
  DELETE_AREA_LINK: 'api/enter-alert/camera/{camera_code}/setting/area-link/{id}',
  ENTER_ALERT_RESULT: 'api/enter-alert/camera/{cameraCode}/results',
  ENTER_ALERT_RESULT_CSV: 'api/enter-alert/camera/{cameraCode}/results/csv',
  ENTER_ALERT_SETTING: 'api/enter-alert/camera/{cameraCode}/setting',
  ENTER_ALERT_DETECTION_SETTING: 'api/enter-alert/camera/{cameraCode}/setting/detection-setting',
  ENTER_ALERT_DETECTION_REPORT: 'api/enter-alert/camera/{cameraCode}/results/report',
  ENTER_ALERT_DETECTION_REPORT_FILE: 'api/enter-alert/camera/{cameraCode}/results/report/file',
  PERSON_TRACKING_REQUESTS: 'api/person-tracking/camera/requests',
  PERSON_TRACKING_CAMERA_RESULTS: 'api/person-tracking/camera/{analysisId}/results',
  NOTIFICATIONS_VAPID: 'api/notifications/vapid',
  NOTIFICATIONS_SUBSCRIPTION: 'api/notifications/subscription',
  GET_PERSON_TRACKING_CAMERA_SETTiNG: 'api/person-tracking/camera/{camera_code}/setting',
  UPDATE_PERSON_TRACKING_CAMERA_SETTiNG:
    'api/person-tracking/camera/{camera_code}/setting/threshold',
  PERSON_TRACKING_ANALYSIS: 'api/person-tracking/camera/{cameraCode}/analysis',
  PERSON_TRACKING_ANALYSIS_REQUEST: 'api/person-tracking/camera/{cameraCode}/analysis_request',
  PERSON_TRACKING_RESULTS_CSV: 'api/person-tracking/camera/{analysisId}/results/csv',
  PERSON_TRACKING_ANALYSIS_SETTiNG: 'api/person-tracking/camera/{analysisId}/analysis/setting',
  GET_OPERATION_ANALYSIS_SETTING: 'api/operation-analysis/camera/{cameraCode}/setting',
  PUT_OPERATION_ANALYSIS_SETTING:
    'api/operation-analysis/camera/{cameraCode}/setting/detection-setting',
  PUT_OPERATION_ANALYSIS_AREA_SETTING:
    'api/operation-analysis/camera/{cameraCode}/setting/area-setting',
  OPERATION_ANALYSIS_REQUEST: 'api/operation-analysis/camera/{camera_code}/analysis_request',
  OPERATION_ANALYSIS_REQUEST_INFORMATION: 'api/operation-analysis/camera/{cameraCode}/analysis',
  OPERATION_ANALYSIS_REQUESTS: 'api/operation-analysis/camera/requests',
  OPERATION_ANALYSIS_RESULTS_RESULTS: 'api/operation-analysis/camera/{analysisId}/results',
  OPERATION_ANALYSIS_RESULTS_CSV: 'api/operation-analysis/camera/{analysisId}/results/csv',
  OPERATION_ANALYSIS_RESULTS_JSON: 'api/operation-analysis/camera/{analysisId}/results/json',
  OPERATION_ANALYSIS_CAMERA_SETTING: 'api/operation-analysis/camera/{analysisId}/analysis/setting',
  OPERATION_ANALYSIS_CAMERA_VIDEO: 'api/operation-analysis/camera/{analysisId}/results/video',
  OPERATION_ANALYSIS_INPUT_AREA_SETTING:
    'api/operation-analysis/camera/{cameraCode}/setting/area-setting/upload/json',
  OPERATION_ANALYSIS_OUTPUT_AREA_SETTING:
    'api/operation-analysis/camera/{cameraCode}/setting/area-setting/json',
  OPERATION_ANALYSIS_RESULTS_OUTPUT_AREA_SETTING:
    'api/operation-analysis/camera/{analysisId}/analysis/setting/area-setting/json',
  RELEASES: 'api/releases',
  RELEASE_DETAIL: 'api/releases/{id}',
  EVENTS_MOTION: 'api/cameras/{cameraCode}/events/motion',
  DEBUG: 'api/debug',
}
