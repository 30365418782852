import urlBase64ToUint8Array from 'urlb64touint8array'
import http from '@/js/utils/http'
import API from '@/js/const/api'
import { getAddTime, getIsoDateStr } from '@/js/utils/date'
import { getEquipmentType } from '@/js/utils/util'
import APP from '@/js/const/app'

const state = {
  registration: null,
  subscription: null,
  // Notification.permissionをstoreで管理する理由はcomputedでリアクティブに利用したい為
  notificationPermission: null,
  notificationSubscribeDialogExpire: null,
}

const mutations = {
  setRegistration(state, registration) {
    state.registration = registration
  },
  setSubscription(state, subscription) {
    state.subscription = subscription
  },
  setNotificationPermission(state) {
    state.notificationPermission = Notification.permission
  },
  setNotificationSubscribeDialogExpire(state, notificationSubscribeDialogExpire) {
    state.notificationSubscribeDialogExpire = notificationSubscribeDialogExpire
  },
}

const getters = {
  /**
   * 未読情報を取得
   * @return {Array} 未読の情報
   */
  registration(state) {
    return state.registration
  },
  /**
   * PushSubscriptionを取得
   * @return {Object} PushSubscription
   */
  subscription(state) {
    return state.subscription
  },
  /**
   * webプッシュ購読済みフラグ
   * @return {Boolean} 購読の許可とsubscriptionデータがある場合true
   */
  isSubscribed(state) {
    return state.notificationPermission === 'granted' && !!state.subscription
  },
  /**
   * webプッシュ購読状態
   * @return {String} denied、granted、default
   */
  notificationPermission(state) {
    return state.notificationPermission
  },
  /**
   * webプッシュ購読を促すダイアログの表示状態
   * @return {Boolean} 表示済みの場合はtrue、未表示の場合はfalse
   */
  notificationSubscribeDialogExpire(state) {
    return state.notificationSubscribeDialogExpire
  },
  /**
   * webプッシュのサポートの可否
   * @return {Boolean}
   */
  isWebPushSupported() {
    // Androidも利用できるがPC限定にする
    return 'serviceWorker' in navigator && 'PushManager' in window && getEquipmentType() === 'pc'
  },
}

const actions = {
  /**
   * service workerからPushSubscriptionの取得
   * @param {Object} context storeのcontext
   * @return {Promise} プロミスオブジェクト
   */
  async getSubscription({ state }) {
    const res = await state.registration.pushManager.getSubscription()
    return res
  },

  /**
   * PushSubscriptionの保存
   * @param {Object} context storeのcontext
   * @return {Promise} プロミスオブジェクト
   */
  async saveSubscription({ state, commit }) {
    // 公開鍵の取得
    const res = await http.get(API.NOTIFICATIONS_VAPID)
    const applicationServerKey = res.data.data.publicKey
    try {
      // 通知権限の許可

      const subscription = await state.registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(applicationServerKey), // Base64形式をUnit8形式に変換する
      })

      const endpoint = subscription.endpoint // エンドポイントのURL
      const rawPublicKey = subscription.getKey('p256dh') // クライアント公開鍵
      const rawAuthSecret = subscription.getKey('auth') // クライアントの認証シークレット

      // プッシュ通知の送信時に指定するContent-Encoding
      let contentEncoding = 'aesgcm'
      if (
        'supportedContentEncodings' in PushManager &&
        PushManager.supportedContentEncodings.includes('aes128gcm')
      ) {
        contentEncoding = 'aes128gcm'
      }

      const params = {
        endpoint: endpoint,
        publicKey: rawPublicKey
          ? btoa(String.fromCharCode.apply(null, new Uint8Array(rawPublicKey)))
          : '',
        authSecret: rawAuthSecret
          ? btoa(String.fromCharCode.apply(null, new Uint8Array(rawAuthSecret)))
          : '',
        contentEncoding: contentEncoding,
        // 無駄な通知が発生しないように期限を短く設定
        expires: getIsoDateStr(
          getAddTime(new Date(), APP.PUSH_SUBSCRIPTION_EXPIRES, 'milliseconds')
        ),
      }
      try {
        await http.post(API.NOTIFICATIONS_SUBSCRIPTION, params)
        // storeにPush Subscriptionの保存
        commit('setSubscription', subscription)
      } catch {
        // storeにPush Subscriptionを削除
        commit('setSubscription', null)
      }
    } finally {
      // 購読状態をstoreにセット (gettersのnotificationPermissionをリアクティブで利用したい為)
      commit('setNotificationPermission')
    }
  },
  /**
   * サーバ側のWeb Push Subscriptionの削除(不要な通知処理を行わない為に利用)
   * @param {Object} context storeのcontext
   * @return {Promise} プロミスオブジェクト
   */
  async deleteSubscription({ state }) {
    // サーバー側を削除
    await http.delete(API.NOTIFICATIONS_SUBSCRIPTION, { endpoint: state.subscription.endpoint })
  },
  /**
   * Web Push Subscriptionの削除(ユーザー操作で購読を中止した場合に利用)
   * @param {Object} context storeのcontext
   * @return {Promise} プロミスオブジェクト
   */
  async subscriptionUnsubscribe({ state, commit, dispatch }) {
    // DB側のPush Subscription削除
    await dispatch('deleteSubscription')
    // service worker側のPush Subscription削除
    await state.subscription.unsubscribe()
    commit('setSubscription', null)
  },
  /**
   * 設定を初期値にクリア
   * @param {Object} context storeのcontext
   * @return {Promise} プロミスオブジェクト
   */
  async resetSetting({ state, commit, dispatch }) {
    if (state.subscription) {
      await dispatch('subscriptionUnsubscribe')
    }
    commit('setNotificationSubscribeDialogExpire', null)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
