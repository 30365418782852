<template>
  <div id="app">
    <el-config-provider :locale="locale">
      <router-view />
    </el-config-provider>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { ElConfigProvider } from 'element-plus'
import '@/assets/style/global.css'
import ja from 'element-plus/dist/locale/ja.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import store from '@/store'

const lang = {
  ja,
  en,
}
const locale = computed(() => lang[store.getters['app/langInfo']])
</script>
<style lang="scss">
#app {
  height: 100%;
}
</style>
