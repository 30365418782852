let requests = []
let requestBusy = false
let requestUpdateInterval = null

const request = () => {
  if (requestBusy) {
    return
  }
  const nextRequest = requests.shift() // 先頭
  if (nextRequest) {
    // 完了していれば次のリクエストを処理
    requestBusy = true
    setTimeout(() => {
      new Promise(nextRequest).then(() => {
        requestBusy = false
        request()
      })
    }, requestUpdateInterval)
  }
}

export default {
  /**
   * ライブ映像取得と表示
   * @param {Number} updateInterval 映像の描画間隔
   */
  requestNext: (updateInterval = 200) => {
    requestUpdateInterval = updateInterval
    request()
  },
  /**
   * リクエストの追加
   * @param {Function} func ライブ画像取得Que
   */
  addRequest(func) {
    requests.push(func)
  },
  /**
   * リクエストのリセット
   * @param {Function} func ライブ画像取得キュー
   */
  resetRequest() {
    requests = []
    requestBusy = false
  },
}
