import http from '@/js/utils/http'
import API from '@/js/const/api'
import { deepCopy } from '@/js/utils/util'

const state = {
  tags: [],
}

const mutations = {
  setTags(state, payload) {
    state.tags = payload
  },
}

const getters = {
  getTagFromCode(state) {
    return (code) => {
      if (state.tags.length === 0) {
        return null
      }

      return state.tags.filter((d) => d.tag_code === code)[0]
    }
  },
  getHasCamerasTags(state) {
    return state.tags.filter((d) => d.cameras.length > 0)
  },
  tags(state) {
    return deepCopy(state.tags)
  },
}

const actions = {
  getTags({ commit }, payload) {
    return http.get(API.CAMERA_TAGS, payload).then((res) => {
      commit('setTags', res.data.data.tags)
    })
  },
  addTag({ commit, getters }, payload) {
    return http.post(API.CAMERA_TAGS, payload).then((res) => {
      commit('setTags', getters.tags.concat([res.data.data]))
    })
  },
  deleteTag({ commit, getters }, payload) {
    const apiPath = API.UPDATE_CAMERA_TAGS.replace('{tag_code}', payload)
    return http.delete(apiPath).then(() => {
      commit(
        'setTags',
        getters.tags.filter((d) => d.tag_code !== payload)
      )
    })
  },
  editTag({ commit, getters }, payload) {
    const apiPath = API.UPDATE_CAMERA_TAGS.replace('{tag_code}', payload.code)

    return http.put(apiPath, { tag_name: payload.name }).then(() => {
      const tags = getters.tags.map((d) => {
        if (d.tag_code === payload.code) {
          d.tag_name = payload.name
        }
        return d
      })

      commit('setTags', tags)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
