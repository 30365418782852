const state = {
  liveCanvasElm: null,
}

const mutations = {
  setLiveCanvasElm(state, liveCanvasElm) {
    state.liveCanvasElm = liveCanvasElm
  },
}

const getters = {
  liveCanvasElm(state) {
    return state.liveCanvasElm
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
