import API from '@/js/const/api'
import http from '@/js/utils/http'

const state = {
  childUsers: null,
}

const mutations = {
  setChildUsers(state, payload) {
    state.childUsers = payload
  },
}

const getters = {
  childUsers(state) {
    return state.childUsers
  },
}

const actions = {
  /**
   * 子アカウント情報を取得
   * @param {Object} context storeのcontext
   * @return {Promise} プロミスオブジェクト
   */
  getChildUsers({ commit }) {
    return http.get(API.CHILD_USERS).then((res) => {
      commit('setChildUsers', res.data.data)
    })
  },
  /**
   * 子アカウント情報の更新
   * @param {Object} context storeのcontext
   * @param {Object} payload リクエストパラメータ
   * @return {Promise} プロミスオブジェクト
   */
  updateChildUser({ commit, state }, { userId, params }) {
    const apiPath = API.CHILD_USER_UPDATE.replace('{userId}', userId)

    return http.put(apiPath, params).then((res) => {
      const users = state.childUsers.map((d) => {
        if (d.uid === userId) {
          d = res.data.data
        }
        return d
      })

      commit('setChildUsers', users)
    })
  },
  /**
   * 子アカウントの端末認証を解除
   * @param {String} userId 削除対象のuser id
   * @return {Promise} プロミスオブジェクト
   */
  deleteAppKey({}, userId) {
    const apiPath = API.CHILD_USER_DELETE_APPKEY.replace('{userId}', userId)
    return http.delete(apiPath)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
