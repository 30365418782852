import i18n from '@/plugins/i18n'
import VueI18n from 'vue-i18n'
/**
 * auth error message設定
 * @param  reason 失敗理由
 * @return メッセージの内容
 */
export function authErrorMessage(reason: string): VueI18n.TranslateResult {
  let message = i18n.global.t('auth_js.password')
  switch (reason) {
    case 'appKey':
      message = `${i18n.global.t('auth_js.device_authentication_failed')}<br/>${i18n.global.t(
        'auth_js.can_only_log'
      )}`
      break
    case 'locked':
      message = i18n.global.t('auth_js.lock_authentication_failed')
      break
    case 'ipRestricted':
      message = i18n.global.t('auth_js.ip_filter_authentication_failed')
      break
    case 'lockedBySystem':
      message = i18n.global.t('auth_js.lock_system_authentication_failed')
      break
  }
  return message
}
