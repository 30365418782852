<template>
  <div v-if="displayLive" class="live">
    <!-- 標準表示 -->
    <canvas
      v-if="!isFisheyeView"
      ref="canvas"
      :class="{ full_canvas: fullSizeFlag && !fullScreenFlag }"
      :width="displaySize.width"
      :height="displaySize.height"
    ></canvas>
    <!-- 360度表示
         画像のdomをfisheye-viewer内で参照する関係で画像のロードが完了したら表示させる
    -->
    <fisheye-viewer
      v-else-if="isLoaded"
      ref="fisheyeViewer"
      :live-img="image"
      :display-size="displaySize"
      :live-image-src="imageSrc"
      :camera-detail="cameraDetail"
    />

    <div class="spinner_wrap" v-if="!isLoaded || requestError">
      <spinner class="spinner" />
      <div v-show="requestError" class="loading_text_wrap">
        <p class="loading_text">{{ $t('live.loading_video') }}</p>
        <p class="loading_text_sub">
          {{ $t('live.please_reload') }}
        </p>
      </div>
    </div>
  </div>
  <div v-else class="no_data" :style="noDataStyle">
    <p class="no_data_text1">{{ $t('live.cannot_live') }}</p>
    <p class="no_data_text2">
      {{ $t('live.please_contact') }}
    </p>
  </div>
</template>

<script>
import http from '@/js/utils/http'
import API from '@/js/const/api'
import Spinner from '@/components/atoms/Spinner'
import FisheyeViewer from '@/components/organisms/FisheyeViewer'
import { getDateObject } from '@/js/utils/date'
import { mapMutations } from 'vuex'

const RETRY_SEC_INITIAL = 1
const RETRY_MSEC_MAX = 1000 * 60

export default {
  name: 'Live',
  components: {
    Spinner,
    FisheyeViewer,
  },
  props: {
    cameraDetail: {
      require: true,
      type: Object,
      default: () => {},
    },
    displaySize: {
      require: true,
      type: Object,
      default: () => {},
    },
    fullScreenFlag: {
      type: Boolean,
      default: false,
    },
    updateInterval: {
      type: Number,
    },
    fullSizeFlag: {
      type: Boolean,
      default: false,
    },
    isFisheyeView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['imgUpdate'],
  data() {
    return {
      ctx: null,
      isLoaded: false,
      hasData: true,
      // リクエストの成功、失敗用フラグ
      isRequestSuccess: true,
      // リクエストした回数
      retryCount: 0,
      liveImgUrl: null,
      startLiveTimeoutId: null,
      retryTimeoutId: null,
      isApi: false,
      image: new Image(),
      useRequestIntervalZero: true,
      imageSrc: null,
    }
  },
  async mounted() {
    // 標準表示用
    if (!this.isFisheyeView) {
      this.ctx = this.$refs.canvas.getContext('2d')
      // zoom用にcanvasエレメントをセット
      this.setLiveCanvasElm(this.$refs.canvas)
    }
    // SCPSのライブ配信をスタート
    await this.startLive()
    // ライブ表示
    this.getLiveImg()
  },
  unmounted() {
    if (this.startLiveTimeoutId) {
      clearTimeout(this.startLiveTimeoutId)
    }
    if (this.retryTimeoutId) {
      clearTimeout(this.retryTimeoutId)
    }
    this.image = null
  },
  computed: {
    noDataStyle() {
      return {
        height: `${this.displaySize.height}px`,
      }
    },
    displayLive() {
      return this.hasData
    },
    requestError() {
      return this.hasData && !this.isRequestSuccess
    },
    interval() {
      // 初回表示時、もしくはAPIリクエストに失敗した場合は次のリクエストが即時実行されるようにインターバルを0にする
      if (this.useRequestIntervalZero) {
        return 0
      }

      if (this.isFisheyeView) {
        return this.fisheyeUpdateInterval
      } else {
        return this.updateInterval ? this.updateInterval : 1000 / this.cameraDetail.image_fps
      }
    },
    retryInterval() {
      const interval = RETRY_SEC_INITIAL * 1.5 ** this.retryCount * 1000
      // 1分を上限にする
      return interval > RETRY_MSEC_MAX ? RETRY_MSEC_MAX : interval
    },
  },
  methods: {
    ...mapMutations({
      setLiveCanvasElm: 'live/setLiveCanvasElm',
    }),
    /**
     * ライブ配信のスタート(SCPS用だが全てのカメラで実行)
     */
    async startLive() {
      // clear用のtimerIdを初期化
      this.startLiveTimeoutId = null

      const apiPath = API.START_LIVE.replace('{camera_code}', this.cameraDetail.camera_code)
      const res = await http.put(apiPath)

      if (res) {
        this.liveImgUrl = res.data.data.url
        this.isApi = res.data.data.isApi
        // 最低5分間に1回SCPSのライブ配信開始のAPIを呼ぶ必要がある（実装は1分間隔にする)
        this.startLiveTimeoutId = setTimeout(this.startLive, 60 * 1000)
      }
    },
    /**
     * ライブ画像の取得
     */
    getLiveImg() {
      // clear用のtimerIdを初期化
      this.retryTimeoutId = null

      // コンポーネント破棄された場合は以降実行しない(無限ループになる為)
      if (!this.image) {
        return
      }

      // LIVE画像を取得
      // SCPSの場合はwithCredentialsにfalseを設定
      http.getLiveImg(
        this.liveImgUrl,
        { responseType: 'arraybuffer', withCredentials: this.isApi },
        (res) => {
          // 1分+送信間隔を超える遅延は破棄
          const modified = getDateObject(res.headers['last-modified'])
          const dt = new Date()
          dt.setSeconds(dt.getSeconds() - (60 + this.cameraDetail.live_interval))
          if (modified < dt) {
            throw new Error('image older than threshold.')
          }

          this.createImg(res.data)
          // リクエストに成功したらリトライカウントを初期化
          this.retryCount = 0
          // APIリクエストに成功したら次からはFPSの間隔で実行する
          this.useRequestIntervalZero = false
          // リクエストが成功したらプリローダーを非表示
          this.isRequestSuccess = true
        },
        () => {
          this.retryTimeoutId = setTimeout(this.getLiveImg, this.retryInterval)
          this.retryCount++
          // エラーリトライ時は、APIリクエストを即時実行したいのでインターバルを0にする
          this.useRequestIntervalZero = true
          // リクエストに失敗したらローディングテキストを表示
          this.isRequestSuccess = false
        },
        this.interval,
        // キャンセルトークン用
        this.cameraDetail.camera_code
      )
    },
    /**
     * ライブ画像の作成
     * @param {ArrayBuffer} data 画像のバイナリデータ
     */
    createImg(data) {
      const blob = new Blob([data], { type: 'image/jpeg' })
      const url = window.URL || window.webkitURL

      if (this.image.src) {
        // メモリから削除
        url.revokeObjectURL(this.image.src)
      }

      this.image.src = url.createObjectURL(blob)

      // 画像更新時もレンダリングする為に利用(360度表示用)
      this.imageSrc = this.image.src

      this.image.onload = () => {
        this.isLoaded = true

        this.$emit('imgUpdate', this.image)

        this.getLiveImg()

        // 標準表示は直接canvasに描画する
        if (!this.isFisheyeView) {
          this.drawImage()
        }
      }

      this.image.onerror = () => {
        this.hasData = false
      }
    },
    /**
     * ライブ画像の描画
     */
    drawImage() {
      // 画像をcanvasに描画
      this.ctx.drawImage(this.image, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height)
    },
  },
}
</script>

<style scoped lang="scss">
.full_canvas {
  width: 100%;
}
.live {
  height: 100%;
  width: 100%;
  line-height: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner_wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .loading_text_wrap {
    text-align: center;
    line-height: 1.7;
    width: 90%;
    margin: 0 auto;
  }

  .loading_text {
    margin-top: $size-l;
    color: $color-white;
    text-shadow: 0 0 2px $color-dark1;
  }
  .loading_text_sub {
    margin-top: $size-s;
    @include fs($font-size-7);
    color: $color-white;
    text-shadow: 0 0 4px $color-dark1;
  }
}

.no_data {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-dark2;
  text-align: center;
  font-weight: bold;
  padding: 0 $size-m;
  flex-direction: column;
  line-height: 1.7;
  .no_data_text1 {
    margin-bottom: $size-m;
  }
  .no_data_text2 {
    @include fs($font-size-6);
    font-weight: normal;
    width: 100%;
  }
}
</style>
