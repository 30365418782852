<template>
  <div id="gmap">
    <GoogleMap
      ref="googleMap"
      :api-key="settings.googleMapsApiKey"
      :center="{ lat: mapLat, lng: mapLng }"
      :zoom="zoom"
      :style="mapStyle"
      :zoom-control="true"
      :street-View-control="false"
      :styles="mapType"
      draggableCursor="default"
      draggingCursor="move"
      :language="$t('main_js.lang')"
      @idle="change"
    >
      <!-- マーカー -->
      <template v-for="(marker, index) in markers" :key="index">
        <Marker :options="getMarkerOptions(marker)" @click="toggleMarker(marker)" />
      </template>
      <!-- 警報関連のポリゴン -->
      <template v-for="(advisoryData, index) in advisoryPaths" :key="index + 'advisory'">
        <Polygon :options="getAdvisoryPolygonOptions(advisoryData)" />
      </template>
      <template v-for="(emergencyData, index) in emergencyPaths" :key="index + 'emergency'">
        <Polygon :options="getEmergencyPolygonOptions(emergencyData)" />
      </template>
      <template v-for="(warningData, index) in warningPaths" :key="index + 'warning'">
        <Polygon :options="getWarningPolygonOptions(warningData)" />
      </template>
    </GoogleMap>
  </div>
</template>

<script>
import http from '@/js/utils/http.js'
import API from '@/js/const/api'
import APP from '@/js/const/app'
import i18n from '@/plugins/i18n'
import { zeroPadding } from '@/js/utils/filters'
import { GoogleMap, Marker, Polygon } from 'vue3-google-map'
import { mapGetters } from 'vuex'

// 皇宮の座標
const BASE_LAT_LON = { lat: 35.6852797, lng: 139.7516308 }
const RED_COLOR = '#ff0000'
const BLUE_COLOR = '#0000ff'
const YELLOW_COLOR = '#FFFF00'
const PURPLE_COLOR = '#800080'
const GREEN_COLOR = '#008800'
const RED_ICON_URL = '//maps.google.com/mapfiles/ms/icons/red-dot.png'
const GREEN_ICON_URL = '//maps.google.com/mapfiles/ms/icons/green-dot.png'

export default {
  name: 'MapView',
  components: { GoogleMap, Marker, Polygon },
  data() {
    return {
      markers: [],
      zoom: 16,
      mapZoom: 16,
      cityData: [],
      prefData: [],
      rangeCameraData: [],
      totalCount: 0,
      weatherMessageState: true,
      mapLat: BASE_LAT_LON.lat,
      mapLng: BASE_LAT_LON.lng,
      selectMarker: null,
      advisoryPaths: [],
      emergencyPaths: [],
      warningPaths: [],
      isMapChange: false,
      mapType: APP.MAP_TYPE,
    }
  },
  props: {
    cameraLatLon: {
      type: Array,
    },
    firstCamera: {
      type: Object,
    },
    weatherApiInfo: {
      type: Array,
    },
    backMarker: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['hideLocationCameraList', 'locationCameraList', 'backMarkerStateChange'],
  computed: {
    ...mapGetters({
      settings: 'app/settings',
    }),
    mapStyle() {
      return {
        width: '100%',
        height: '100%',
      }
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async initialize() {
      // リストで初めてのカメラに問題がある場合
      if (
        !this.firstCamera ||
        this.firstCamera.location_latitude > 90 ||
        this.firstCamera.location_longitude > 180
      ) {
        this.mapLat = BASE_LAT_LON.lat
        this.mapLng = BASE_LAT_LON.lng
        // geolocationUse = true
      } else {
        this.mapLat = this.firstCamera.location_latitude
        this.mapLng = this.firstCamera.location_longitude
      }
      // カメラや地役のデータ
      await this.getCameraDataByCity()
      await this.getCameraDataByArea()
    },
    isZoomCountry(zoom) {
      return zoom <= 5
    },
    isZoomPref(zoom) {
      return zoom > 5 && zoom <= 7
    },
    isZoomCity(zoom) {
      return zoom > 7 && zoom <= 12
    },
    isZoomLocation(zoom) {
      return zoom > 12
    },
    getCameraDataByCity() {
      return http.get(API.CAMERAS_LOCATION_CITY).then((res) => {
        this.cityData = res.data.data
      })
    },
    //  カメラのマーカ
    addLocationMarkers() {
      const bounds = this.$refs.googleMap.map.getBounds()
      const sw = bounds.getSouthWest()
      const ne = bounds.getNorthEast()
      this.getCameraDataByRange(sw.lat(), ne.lat(), sw.lng(), ne.lng())
    },
    //  都市のマーカ
    addCityMarkers() {
      this.markers = []
      this.cityData.forEach((one) => {
        const text = one.city_jp + ': ' + one.camera_count
        const position = {
          position: { lat: Number(one.latitude), lng: Number(one.longitude) },
          label: {
            text: text,
            color: BLUE_COLOR,
            fontWeight: 'bold',
            fontFamily: 'Courier New, monospace',
          },
          markerIcon: {
            url: GREEN_ICON_URL,
            origin: new this.$refs.googleMap.api.Point(100, 0),
          },
          clickable: false,
        }
        this.markers.push(position)
      })
      this.isMapChange = false
    },
    //  県のマーカ
    addPrefMarkers() {
      this.markers = []
      for (const i in this.prefData) {
        const one = this.prefData[i]
        let area = one.area_jp
        if (area !== '北海道') {
          area = area.substring(0, area.length - 1)
        }
        const count = one.camera_count
        const text = area + ': ' + count
        const position = {
          position: { lat: Number(one.latitude), lng: Number(one.longitude) },
          label: {
            text: text,
            color: RED_COLOR,
            fontWeight: 'bold',
            fontFamily: 'Courier New, monospace',
          },
          markerIcon: {
            url: GREEN_ICON_URL,
            origin: new this.$refs.googleMap.api.Point(100, 0),
          },
          clickable: false,
        }
        this.markers.push(position)
      }
      this.isMapChange = false
    },
    //  国のマーカ
    addCountryMarker() {
      this.markers = []
      const lat = 35.686198
      const lng = 139.753211
      const text = i18n.global.t('map_view.japan') + this.totalCount
      const position = {
        position: { lat: lat, lng: lng },
        label: {
          text: text,
          color: RED_COLOR,
          fontWeight: 'bold',
          fontFamily: 'Courier New, monospace',
        },
        markerIcon: {
          url: GREEN_ICON_URL,
          origin: new this.$refs.googleMap.api.Point(100, 0),
        },
        clickable: false,
      }
      this.markers.push(position)
      this.isMapChange = false
    },
    //  カメラデータ
    getCameraDataByRange(latMin, latMax, lngMin, lngMax) {
      const cameraRangeApi = API.CAMERAS_LOCATION_RANGE
      const params = {
        lat_min: latMin,
        lat_max: latMax,
        lng_min: lngMin,
        lng_max: lngMax,
      }

      return http.get(cameraRangeApi, params).then((res) => {
        if (!this.isZoomLocation(this.zoom)) {
          return
        }
        this.rangeCameraData = res.data.data
        this.markers = []
        for (const i in this.rangeCameraData) {
          const one = this.rangeCameraData[i]
          const position = {
            position: { lat: one.latitude, lng: one.longitude },
            label: {
              text: one.name,
              color: GREEN_COLOR,
              fontWeight: 'bold',
              fontFamily: 'Courier New, monospace',
            },
            code: one.code,
            markerIcon: {
              url: GREEN_ICON_URL,
              labelOrigin: new this.$refs.googleMap.api.Point(15, 40),
            },
            clickable: true,
          }
          this.markers.push(position)
        }
        if (this.selectMarker) {
          this.markers.push(this.selectMarker)
        }
        this.isMapChange = false
      })
    },
    getCameraDataByArea() {
      return http.get(API.CAMERAS_LOCATION_AREA).then((res) => {
        this.prefData = res.data.data
        for (const i in this.prefData) {
          const count = this.prefData[i].camera_count
          this.totalCount += count
        }
      })
    },
    change() {
      if (this.isMapChange) {
        return
      }
      this.isMapChange = true
      this.onMarkers()
    },
    //  状態によってマーカ修正
    onMarkers() {
      const map = this.$refs.googleMap.map
      if (this.isZoomLocation(map.zoom)) {
        this.addLocationMarkers()
      } else if (this.isZoomCity(map.zoom)) {
        if (this.isZoomLocation(this.mapZoom)) {
          if (this.selectMarker) {
            this.$emit('hideLocationCameraList')
          }
        }
        this.addCityMarkers()
      } else if (this.isZoomPref(map.zoom)) {
        this.addPrefMarkers()
      } else if (this.isZoomCountry(map.zoom)) {
        this.addCountryMarker()
      }
      this.mapZoom = map.zoom
    },
    // カメラリスト開く
    toggleMarker(marker) {
      if (this.isMapChange || !marker.clickable) {
        return
      }
      if (marker.code) {
        this.$emit('locationCameraList', marker.code)
      }
      if (marker.markerIcon) {
        if (marker.markerIcon.url === GREEN_ICON_URL) {
          marker.markerIcon = {
            url: RED_ICON_URL,
            labelOrigin: new this.$refs.googleMap.api.Point(15, 40),
          }
          marker.label = {
            text: marker.label.text,
            color: RED_COLOR,
            fontWeight: 'bold',
            fontFamily: 'Courier New, monospace',
          }
          // 前の選択マーカーを戻す
          if (this.selectMarker) {
            this.greenMarker()
          }
          this.selectMarker = marker
        }
      }
    },
    greenMarker() {
      this.selectMarker.markerIcon = {
        url: GREEN_ICON_URL,
        labelOrigin: new this.$refs.googleMap.api.Point(15, 40),
      }
      this.selectMarker.label = {
        text: this.selectMarker.label.text,
        color: GREEN_COLOR,
        fontWeight: 'bold',
        fontFamily: 'Courier New, monospace',
      }
    },
    // レイヤ情報や適用
    getWeatherInfo(date, time, type) {
      date =
        String(date.getFullYear()) +
        zeroPadding(date.getMonth() + 1, 2) +
        zeroPadding(date.getDate(), 2)
      time = zeroPadding(time, 2)
      const weatherApi = API.WEATHER_INFO.replace(
        '{date}/{time}/{type}',
        date + '/' + time + '/' + type
      )
      http
        .get(weatherApi)
        .then((res) => {
          const paths = this.getPaths(res.data.data.features)
          if (type.startsWith('advisory')) {
            this.advisoryPaths = paths
          } else if (type.startsWith('emergency_warning')) {
            this.emergencyPaths = paths
          } else if (type.startsWith('warning')) {
            this.warningPaths = paths
          }
        })
        .catch((error) => {
          //  メッセージの重複防止
          if (this.weatherMessageState) {
            if (error.response.status === 404) {
              this.$message({
                message: i18n.global.t('map_view.select_date'),
                type: 'warning',
              })
            } else {
              this.$message({
                message: i18n.global.t('map_view.communication_failed'),
                type: 'error',
              })
            }
            this.weatherMessageState = false
          }
        })
    },
    /**
     * WKT 形式のポリゴンデータを GmapPolygon の paths に変換
     */
    getPaths(features) {
      const paths = []
      for (const i in features) {
        const geometry = features[i].geometry
        if (geometry.type === 'Polygon') {
          const area = geometry.coordinates[0].map((p) => {
            return { lat: parseFloat(p[1]), lng: parseFloat(p[0]) }
          })
          paths.push({ path: area })
        } else if (geometry.type === 'MultiPolygon') {
          const polygons = geometry.coordinates
          for (const j in polygons) {
            const area = polygons[j][0].map((p) => {
              return { lat: parseFloat(p[1]), lng: parseFloat(p[0]) }
            })
            paths.push({ path: area })
          }
        }
      }
      return paths
    },
    setWeatherLayer(date, time, weather, typeNumber) {
      this.advisoryPaths = []
      this.emergencyPaths = []
      this.warningPaths = []
      if (typeNumber === 0) {
        return
      }
      if (!weather) {
        this.getWeatherInfo(date, time, 'advisory')
        this.getWeatherInfo(date, time, 'emergency_warning')
        this.getWeatherInfo(date, time, 'warning')
      } else {
        this.getWeatherInfo(date, time, weather)
      }
    },
    getMarkerOptions(marker) {
      return {
        position: marker.position,
        clickable: marker.clickable,
        draggable: false,
        icon: marker.markerIcon,
        label: marker.label,
      }
    },
    getAdvisoryPolygonOptions(data) {
      return {
        paths: data.path,
        draggable: false,
        strokeColor: YELLOW_COLOR,
        fillColor: YELLOW_COLOR,
        fillOpacity: '0.3',
        strokeWeight: 2,
      }
    },
    getEmergencyPolygonOptions(data) {
      return {
        paths: data.path,
        draggable: false,
        strokeColor: PURPLE_COLOR,
        fillColor: PURPLE_COLOR,
        fillOpacity: '0.3',
        strokeWeight: 2,
      }
    },
    getWarningPolygonOptions(data) {
      return {
        paths: data.path,
        draggable: false,
        strokeColor: RED_COLOR,
        fillColor: RED_COLOR,
        fillOpacity: '0.3',
        strokeWeight: 2,
      }
    },
  },
  watch: {
    cameraLatLon() {
      if (!this.isZoomLocation(this.$refs.googleMap.map.zoom)) {
        this.mapZoom = 16
        this.$refs.googleMap.map.setZoom(this.mapZoom)
      }
      if (!this.cameraLatLon || this.cameraLatLon[0] > 90 || this.cameraLatLon[1] > 180) {
        this.mapLat = BASE_LAT_LON.lat
        this.mapLng = BASE_LAT_LON.lng
        this.$message({
          message: i18n.global.t('map_view.problem_latlon'),
          type: 'error',
        })
      } else {
        this.mapLat = this.cameraLatLon[0]
        this.mapLng = this.cameraLatLon[1]
      }
    },
    weatherApiInfo() {
      if (
        this.weatherApiInfo[0] &&
        this.weatherApiInfo[1] &&
        this.weatherApiInfo[4] &&
        !this.weatherApiInfo[5]
      ) {
        this.setWeatherLayer(
          this.weatherApiInfo[0],
          this.weatherApiInfo[1],
          this.weatherApiInfo[2],
          this.weatherApiInfo[3]
        )
      }
    },
    backMarker(v) {
      if (v) {
        this.greenMarker()
        this.$emit('backMarkerStateChange')
      }
    },
  },
}
</script>

<style scoped lang="scss">
#gmap {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
