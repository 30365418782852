import http from '@/js/utils/http'
import API from '@/js/const/api'
import APP from '@/js/const/app'

const defaultTooltipState = {
  // ここのstateはunreadという意味(false = ツールチップ上は既読なのでツールチップは表示させない)
  Download: { date: null, state: false },
  EnterAlert: { date: null, state: false },
  Motion: { date: null, state: false },
  Monitor: { date: null, state: false },
  PersonTracking: { date: null, state: false },
  OperationAnalysis: { date: null, state: false },
}

const state = {
  readState: [],
  tooltipState: defaultTooltipState,
}

const mutations = {
  setNotificationsReadState(state, readState) {
    state.readState = readState
  },
  setTooltipState(state, tooltipState) {
    state.tooltipState = tooltipState
  },
  resetTooltipState(state) {
    state.tooltipState = defaultTooltipState
  },
}

const getters = {
  /**
   * 未読情報を取得
   * @return {Array} 未読の情報
   */
  readState(state) {
    return state.readState
  },
  /**
   * 未読有無を取得
   * @return {Boolean} 未読のお知らせの有無
   */
  hasUnreadNotifications(state) {
    return !!state.readState.find((d) => d.unread)
  },
  /**
   * tooltipのclose状態を取得
   * @return {Object} downloadnoの日時tooltipを閉じたかの有無
   */
  tooltipState(state) {
    return state.tooltipState
  },
  /**
   * 未読の通知情報を取得
   * @return {Array} 未読の通知情報
   */
  unreadNotifications(state) {
    const targetEvents = Object.keys(state.tooltipState).filter(
      (d) => state.tooltipState[d] && state.tooltipState[d].state
    )
    // 未読(unread = true) かつ 対象のイベント(defaultTooltipState)のみに絞り込み
    return state.readState.filter((d) => {
      return d.unread && targetEvents.includes(d.event)
    })
  },
}

const actions = {
  /**
   * 通知既読情報取得
   */
  getNotificationsReadState({ commit, rootGetters }) {
    return http.get(API.NOTIFICATIONS_READ).then((res) => {
      const environmentMode = rootGetters['app/settings'].environmentMode
      const useNotificationKeys = APP.GET_NOTIFICATIONS_TABS(environmentMode).map((d) => d.key)
      const data = res.data.data.filter((d) => {
        return useNotificationKeys.find((key) => key === d.event)
      })

      // 既読情報をセット
      commit('setNotificationsReadState', data)
    })
  },
  /**
   * 通知のステータス更新(未読から既読に変更)
   * @param {String} event お知らせ種別
   * @param {Object} params リクエストパラメーター
   */
  updateReadState({ dispatch }, { event, params }) {
    const apiPath = API.NOTIFICATIONS_READ_UPDATE.replace('{event}', event)
    return http.put(apiPath, params).then(() => {
      // 未読情報を再取得してstateの情報を書き換える
      dispatch('getNotificationsReadState')
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
