import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import Cameras from '@/components/pages/Cameras/main.vue'
import PageBase from '@/components/templates/PageBase.vue'
import Login from '@/components/pages/Login/main.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: PageBase,
    children: [
      {
        path: '',
        redirect: 'list/1',
      },
      {
        path: 'list/:page',
        name: 'list',
        component: Cameras,
      },
      {
        path: 'detail/:code',
        name: 'detail',
        component: () =>
          import(/* webpackChunkName: "detail" */ '@/components/pages/Detail/main.vue'),
        props: (route) => {
          return {
            cameraCode: route.params.code,
            urlParams: route.query,
          }
        },
      },
      {
        path: 'heatmap',
        name: 'heatmap',
        component: () =>
          import(/* webpackChunkName: "heatmap" */ '@/components/pages/HeatMap/main.vue'),
      },
      {
        path: 'setting',
        name: 'setting',
        component: () =>
          import(/* webpackChunkName: "setting" */ '@/components/pages/Setting/main.vue'),
      },
      {
        path: 'child_users',
        name: 'child_users',
        component: () =>
          import(/* webpackChunkName: "child_users" */ '@/components/pages/ChildUsers/main.vue'),
      },
      {
        path: 'notification',
        name: 'notification',
        component: () =>
          import(/* webpackChunkName: "notification" */ '@/components/pages/Notification/main.vue'),
      },
      {
        path: '/notfound',
        name: 'notfound',
        component: () =>
          import(/* webpackChunkName: "notfound" */ '@/components/pages/NotFound.vue'),
      },
      {
        path: '/detail/:code/people-counter',
        name: 'camera_people_counter',
        component: () =>
          import(
            /* webpackChunkName: "people_counter" */ '@/components/pages/PeopleCounter/main.vue'
          ),
        props: (route) => {
          return {
            cameraCode: route.params.code,
            urlParams: route.query,
            pageName: route.name,
          }
        },
      },
      {
        path: '/location/:code/people-counter',
        name: 'location_people_counter',
        component: () =>
          import(
            /* webpackChunkName: "people_counter" */ '@/components/pages/PeopleCounter/main.vue'
          ),
        props: (route) => {
          return {
            cameraCode: route.params.code,
            urlParams: route.query,
            pageName: route.name,
          }
        },
      },
      {
        path: 'detail/:code/entry-detection',
        component: () =>
          import(
            /* webpackChunkName: "entry_detection" */ '@/components/pages/EntryDetection/main.vue'
          ),
        children: [
          {
            path: '',
            name: 'entry_detection',
            component: () =>
              import(
                /* webpackChunkName: "entry_detection_setting" */ '@/components/pages/EntryDetection/Setting.vue'
              ),
            props: (route) => {
              return {
                cameraCode: route.params.code,
              }
            },
          },
          {
            path: 'detail',
            name: 'entry_detection_create',
            component: () =>
              import(
                /* webpackChunkName: "entry_detection_detail" */ '@/components/pages/EntryDetection/Detail.vue'
              ),
            props: (route) => {
              return {
                cameraCode: route.params.code,
              }
            },
          },
          {
            path: 'detail/:id',
            name: 'entry_detection_edit',
            component: () =>
              import(
                /* webpackChunkName: "entry_detection_detail" */ '@/components/pages/EntryDetection/Detail.vue'
              ),
            props: (route) => {
              return {
                areaLinkId: route.params.id,
                cameraCode: route.params.code,
              }
            },
          },
        ],
      },
      {
        path: 'detail/:code/operation-analysis',
        name: 'operation_analysis',
        component: () =>
          import(
            /* webpackChunkName: "operation_analysis" */ '@/components/pages/OperationAnalysis/Setting/main.vue'
          ),
        props: (route) => {
          return { cameraCode: route.params.code }
        },
      },
      {
        path: 'person-tracking/results',
        name: 'person_tracking_results',
        component: () =>
          import(
            /* webpackChunkName: "person_tracking_results" */ '@/components/pages/PersonTracking/Results/main.vue'
          ),
      },
      // 解析結果詳細表示用
      {
        path: 'person-tracking/results/:id',
        name: 'person_tracking_result_detail',
        component: () =>
          import(
            /* webpackChunkName: "person_tracking_result_detail" */ '@/components/pages/PersonTracking/ResultDetail/main.vue'
          ),
        props: (route) => {
          return { analysisId: route.params.id }
        },
      },
      {
        path: 'operation-analysis/results',
        name: 'operation_analysis_results',
        component: () =>
          import(
            /* webpackChunkName: "operation_analysis_results" */ '@/components/pages/OperationAnalysis/Results/main.vue'
          ),
      },
      {
        path: 'operation-analysis/results/:id',
        name: 'operation_analysis_result_detail',
        component: () =>
          import(
            /* webpackChunkName: "operation_analysis_result_detail" */ '@/components/pages/OperationAnalysis/ResultDetail/main.vue'
          ),
        props: (route) => {
          return { analysisId: route.params.id }
        },
      },
      {
        path: 'detail/:code/entry-detection-report',
        name: 'entry_detection_report',
        component: () =>
          import(
            /* webpackChunkName: "entry_detection_report" */ '@/components/pages/EntryDetection/EntryDetectionReport/main.vue'
          ),
      },
      {
        path: '/releases',
        name: 'releases',
        component: () =>
          import(/* webpackChunkName: "releases" */ '@/components/pages/Releases/main.vue'),
      },
      // ユーザー権限、環境変数等設定用のデバッグページ
      {
        path: '/debug',
        name: 'debug',
        component: () =>
          import(/* webpackChunkName: "debug" */ '@/components/pages/Debug/main.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { isPublic: true },
  },
  {
    path: '/password_reissue',
    name: 'password_reissue',
    component: () =>
      import(/* webpackChunkName: "help" */ '@/components/pages/PasswordReissue.vue'),
    meta: { isPublic: true },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "help" */ '@/components/pages/Help/main.vue'),
    meta: { isPublic: true },
  },
  { path: '/:catchAll(.*)', redirect: '/notfound' },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      // ページのパスが変更になった場合のみスクロールする（queryパラメータが変更された場合はスクロールしない)
      if (to.path !== from.path) {
        return {
          top: 0,
        }
      }
    }
  },
})

router.beforeEach((to, from, next) => {
  // isPublic はログイン不要
  if (to.matched.some((record) => record.meta.isPublic)) {
    next()
  } else {
    if (store.getters['auth/isLogin'] === false) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  }

  // bodyにnameをクラス名として設定
  document.body.className = <string>to.name
})

router.afterEach((to) => {
  if (to.matched.some((record) => !record.meta.isPublic)) {
    store.dispatch('auth/loginCheck', to)
  }
})

export default router
