export default {
  CALIBRATION: {
    '1280_720': {
      radius: 426,
      maxZoom: 0.85,
      minZoom: 0.3,
      pitchMax: 0.9,
      pitchMin: 2.2,
      pitchRangeForZoom: 0.35,
    },
    '1920_1080': {
      radius: 587,
      maxZoom: 0.85,
      minZoom: 0.3,
      pitchMax: 0.9,
      pitchMin: 2.2,
      pitchRangeForZoom: 0.35,
    },
    // product_name: "M3067-P"
    '720_720': {
      radius: 330,
      maxZoom: 0.85,
      minZoom: 0.3,
      pitchMax: 0.8,
      pitchMin: 2.1,
      pitchRangeForZoom: 0.4,
    },
    // product_name: "M3067-P"
    '1200_1200': {
      radius: 560,
      maxZoom: 0.85,
      minZoom: 0.3,
      pitchMax: 0.8,
      pitchMin: 2.2,
      pitchRangeForZoom: 0.4,
    },
    // product_name: "M3067-P"
    '1280_1280': {
      radius: 600,
      maxZoom: 0.85,
      minZoom: 0.3,
      pitchMax: 0.7,
      pitchMin: 2.3,
      pitchRangeForZoom: 0.4,
    },
    // product_name: "M3067-P"
    '1440_1440': {
      radius: 680,
      maxZoom: 0.85,
      minZoom: 0.3,
      pitchMax: 0.9,
      pitchMin: 2.3,
      pitchRangeForZoom: 0.35,
    },
    // product_name: "M3067-P"
    '1920_1920': {
      radius: 890,
      maxZoom: 0.85,
      minZoom: 0.3,
      pitchMax: 0.8,
      pitchMin: 2.4,
      pitchRangeForZoom: 0.35,
    },
  },
}
