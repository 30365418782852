<template>
  <div v-if="!requestError" class="timelapse">
    <thumbnail :src="imgSrc" :use-lazy-load="false" :width="`${displaySize.width}px`" />
    <spinner v-if="!isLoaded" class="spinner" />
  </div>
  <div v-else class="no_data">
    <p class="no_data_text1">{{ $t('live.cannot_live') }}</p>
    <p class="no_data_text2">
      {{ $t('live.please_contact') }}
    </p>
  </div>
</template>

<script>
import API from '@/js/const/api'
import http from '@/js/utils/http'
import Spinner from '@/components/atoms/Spinner'
import Thumbnail from '@/components/atoms/Thumbnail'

export default {
  name: 'Live',
  components: {
    Thumbnail,
    Spinner,
  },
  data() {
    return {
      apiPath: null,
      isLoaded: false,
      imgSrc: null,
      timer: null,
      requestError: false,
    }
  },
  props: {
    cameraDetail: {
      type: Object,
    },
    displaySize: {
      type: Object,
    },
  },
  created() {
    this.apiPath = API.START_LIVE.replace('{camera_code}', this.cameraDetail.camera_code)
    this.getLiveImg()
  },
  unmounted() {
    this.clearTimer()
  },
  methods: {
    async getLiveImg() {
      this.clearTimer()
      try {
        this.isLoaded = false
        const res = await http.put(this.apiPath)
        this.imgSrc = res.data.data.url
        this.timer = setTimeout(this.getLiveImg, 60 * 1000)
      } catch (e) {
        this.requestError = true
      } finally {
        this.isLoaded = true
      }
    },
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.timelapse {
  background: rgba($color-dark2, 0.1);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .spinner {
    position: absolute;
    @include x_center;
    @include y_center;
  }
}
.no_data {
  background: rgba($color-dark2, 0.1);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-dark2;
  text-align: center;
  font-weight: bold;
  padding: 0 $size-m;
  flex-direction: column;
  line-height: 1.7;
  .no_data_text1 {
    margin-bottom: $size-m;
  }
  .no_data_text2 {
    @include fs($font-size-6);
    font-weight: normal;
    width: 100%;
  }
}
</style>
