import i18n from '@/plugins/i18n'
import VueI18n from 'vue-i18n'
import { DetectObject } from '@/@types/typescript-axios/api'

/**
 * 物体検知のラベルを取得
 * @param klass 物体検知種別
 * @return 物体検知種別のラベル
 */
export function getObjectLabel(klass: string): VueI18n.TranslateResult {
  let label: VueI18n.TranslateResult = ''
  switch (klass) {
    case 'car':
      label = i18n.global.t('motion_detect_object.car')
      break
    case 'person':
      label = i18n.global.t('motion_detect_object.person')
      break
  }
  return label
}

/**
 * 物体検知カメラ設定の文字列
 * @param setting 物体検知種別
 * @return 物体検知種別のカメラ設定 「person, car」のみ
 */
export function getObjectDetectSettingStr(setting: DetectObject[]): string {
  return setting
    .filter((d) => ['person', 'car'].includes(d.class))
    .map((d) => `${getObjectLabel(d.class)}(${d.threshold})`)
    .join(' / ')
}
